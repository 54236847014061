<mat-form-field style="margin-bottom: -1.25em" class="md:w-72">
    <!-- <mat-label *ngIf="isHomeTask">Account</mat-label> -->
    <mat-select
        [(value)]="accountId"
        [disableOptionCentering]="true"
        placeholder="Select an account"
        (selectionChange)="changeAccount($event)"
        #accountsdropdown
    >
        <div class="sticky-input-container">
            <input
                placeholder="Click here to search"
                (keyup)="onKey($event.target.value)" 
                (click)="$event.stopPropagation()"          
                (keydown)="$event.stopPropagation()"
                class="px-3 h-7"
            >
            <!-- stop propagation to allow for keys like space to be pressed without triggering options -->
        </div>

        <mat-option
            *ngFor="let account of accounts"
            [value]="account.accountId"
        >
            {{ account.accountName }}
        </mat-option>
    </mat-select>
</mat-form-field>


/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReturnsFactorType, ReturnsFactorType2LabelMapping } from 'app/core/enums/job/returns.factor.type';

@Component({
  selector: 'bag-of-words-report',
  templateUrl: './bag-of-words-report.component.html',
  styleUrls: ['./bag-of-words-report.component.scss']
})
export class BagOfWordsReportComponent implements OnInit {

  @Output() onFormChange = new EventEmitter();

  @Input() config: any;
  @Input() viewOnly: any;
  @Input() currency: string;

  @ViewChild('bowNgForm') bowNgForm: NgForm;
  bowForm: UntypedFormGroup;

  configForm: boolean = false;

  protected readonly returnsFactorType = ReturnsFactorType;
  protected readonly returnsFactorType2LabelMapping = ReturnsFactorType2LabelMapping;

  constructor(private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    const configPresent: boolean = this.config ? true: false;
    this.configForm = configPresent;

      this.bowForm = this._formBuilder.group({
        grossMargin:[{value: 0, disabled: this.viewOnly}, []],
        additionalCost:[{value: 0, disabled: this.viewOnly}, []],
        useConversionValueOverride:[{value: false, disabled: this.viewOnly}, []],
        conversionValueOverride:[{value: 0, disabled: this.viewOnly}, []],
        useOfflineFactor:[{value: false, disabled: this.viewOnly}, []],
        offlineFactor:[{value: 0, disabled: this.viewOnly}, []],
        useReturnsFactor:[{value: ReturnsFactorType.Off, disabled: this.viewOnly}, []],
        returnsFactor:[{value: 0, disabled: this.viewOnly}, []]
      });

      if(configPresent){
        this.configForm = true;
        const reportFields: object = this.config.bagOfWordsReportFields;
        this.bowForm.patchValue(reportFields);
      }

      this.bowForm.valueChanges.subscribe((val)=>{
        val.grossMargin /= 100;
        this.onFormChange.emit({bagOfWordsReportFields:val});
      });
      this.onFormChange.emit({bagOfWordsReportFields:this.bowForm.value});
  }

  setBoolVal(field: string, value: boolean): void{
    this.bowForm.get('useConversionValueOverride').setValue(value);
  }
  checkDisplay(field: string, truthValue: string): boolean{
    return !this.configForm || this.bowForm.get(field).value === truthValue;
  }
  checkDisplayOfReturns(field: string, truthValue: ReturnsFactorType): boolean
  {
    return !this.configForm || this.bowForm.get(field).value === truthValue;
  }

  checkDisplayOfValueForm(field: string, truthValue: boolean): boolean
  {
    return !this.configForm || this.bowForm.get(field).value === truthValue;
  }
}

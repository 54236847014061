import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Country, Country2LabelMapping } from 'app/core/enums/misc/country';

@Component({
  selector: 'country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  country2LabelMapping = Country2LabelMapping;
  countries: Country[] = [];

  ngOnInit(): void {
    this.countries = Object.values(Country).filter(
      value => typeof value === 'number'
    ) as Country[];
  }

  onKey(value: string): void {
    this.countries = this.search(value);
  }

  search(value: string): Country[] {
    const filter = value.toLowerCase();
    return Object.values(Country)
      .filter(country => typeof country === 'number')
      .filter((country: Country) =>
        this.country2LabelMapping[country].toLowerCase().includes(filter)
      ) as Country[];
  }
}

import { environment } from 'environments/environment';

export class FrontendApi {
    static readonly login = environment.apiUrl + '/authentication';
    static readonly user = environment.apiUrl + '/users';
    static readonly account = environment.apiUrl + '/accounts';
    static readonly biddingGenius = environment.apiUrl + '/biddinggenius';
    static readonly mlod = environment.apiUrl + '/mlod';
    static readonly reverseMlod = environment.apiUrl + '/reversemlod';
    static readonly productOptimiser = environment.apiUrl + '/productoptimiser';
    static readonly structureBuilder = environment.apiUrl + '/structurebuilder';
    static readonly trackingPixel = environment.apiUrl + '/trackingpixel';
    static readonly bookingManager = environment.apiUrl + '/bookingmanager';
    static readonly logs = environment.apiUrl + '/logs';
    static readonly permanentWords = environment.apiUrl + '/permanentwords';
    static readonly productSearch = environment.apiUrl + '/productsearch';
    static readonly batchUpdates = environment.apiUrl + '/batchupdates';
    static readonly jobScheduler = environment.apiUrl + '/jobscheduler';
    static readonly feed = environment.apiUrl + '/feeds';
    static readonly home = environment.apiUrl + '/home';
    static readonly textAdsUpdateScheduler = environment.apiUrl + '/textAdsUpdateScheduler';
    static readonly feedMaps = environment.apiUrl + '/feedmaps';
}

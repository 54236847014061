// decimal-format.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'decimalFormat',
})
export class DecimalFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: number): string {

    if (value === undefined || value === null) {
      return 'N/A';
    }

    return this.decimalPipe.transform(value, '1.2-2') || 'N/A';
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'file-extension-type',
  templateUrl: './file-extension-type.component.html',
  styleUrls: ['./file-extension-type.component.scss']
})
export class FileExtensionTypeComponent {
  @Input() parentFormGroup;
}

<div class="form-group" [formGroup]="parentFormGroup">
    <div class="my-3" [hidden]="required">
        <mat-label class="font-medium">Set ROAS?</mat-label>
        <mat-button-toggle-group
            [value]="required ? true:false"
            (change)="check()"
            [formControlName]="'setRoas'"
            #roasToggleGroup="matButtonToggleGroup"
        >
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <mat-form-field
        class="example-full-width"
        appearance="fill"
        *ngIf="roasToggleGroup.value === true"
    >
        <mat-label>ROAS</mat-label>
        <input
            id="roasThreshold"
            type="number"
            min="0"
            matInput
            [formControlName]="'roasThreshold'"
        />
    </mat-form-field>
</div>

/* eslint-disable @typescript-eslint/naming-convention */
export enum JobStatus {
    Running = 0,
    Completed = 1,
    Pushed = 2,
    Failed = 3,
    Cancelled = 4,
    Discarded = 5
}

export const JobStatus2LabelMapping: Record<JobStatus, string> = {
    [JobStatus.Running]: 'Running',
    [JobStatus.Completed]: 'Completed',
    [JobStatus.Pushed]: 'Pushed',
    [JobStatus.Failed]: 'Failed',
    [JobStatus.Cancelled]: 'Cancelled',
    [JobStatus.Discarded]: 'Discarded',
};

/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { UserProfile } from 'app/core/models/user.profile';
import { FrontendApi } from '../../shared/frontend.api';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: ReplaySubject<UserProfile> = new ReplaySubject<UserProfile>(
        1
    );

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private injector: Injector) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserProfile) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<UserProfile> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserProfile> {
        const authService = this.injector.get(AuthService);
        const userId = AuthUtils.getUserIdFromToken(authService.accessToken);
        if (userId) {
            return this._httpClient
                .get<UserProfile>(`${FrontendApi.user}/profile/${userId}`)
                .pipe(
                    tap(
                        (user) => {
                            this._user.next(user);
                        },
                        (error) => {
                            if (error instanceof HttpErrorResponse) {
                                console.log('Server is not responding...');
                                //authService.signOut();
                                //location.reload();
                            }
                        }
                    )
                );
        }
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(id: string, params: any): Observable<any> {
        return this._httpClient.put<User>(`${FrontendApi.user}/${id}`, params);
    }

    /**
     * Get all users
     *
     *
     */
    getAll(): Observable<any> {
        return this._httpClient.get<User[]>(`${FrontendApi.user}/view`);
    }

    /**
     * Add new user
     *
     * @param user
     */
    add(user: User): Observable<any> {
        return this._httpClient.post(`${FrontendApi.user}`, user);
    }

    /**
     * Get user by id
     *
     * @param id
     */
    getById(id: string): Observable<User> {
        return this._httpClient.get<User>(`${FrontendApi.user}/${id}`);
    }

    /**
     * Check if username exits
     *
     * @param username
     */
    checkIfUsernameExists(username: string): Observable<any> {
        const userObj = {
            username: username,
        };
        return this._httpClient.post(
            `${FrontendApi.user}/check-username`,
            userObj
        );
    }

    /**
     * Change user password
     *
     * @param user
     */
    changePassword(params: any): Observable<any> {

        const authService = this.injector.get(AuthService);
        const userId = AuthUtils.getUserIdFromToken(authService.accessToken);
        if (userId) {

            return this._httpClient.put(
                `${FrontendApi.user}/change-password/${userId}`,
                params
            );
        }
    }

    /**
     * Update user profile
     *
     * @param user
     */
    updateProfile(params: any): Observable<any> {

        const authService = this.injector.get(AuthService);
        const userId = AuthUtils.getUserIdFromToken(authService.accessToken);
        if (userId) {
            return this._httpClient.put(
                `${FrontendApi.user}/update-profile/${userId}`,
                params
            );
        }
    }

    getUsersDropdown(): Observable<any> {
        return this._httpClient.get<User[]>(`${FrontendApi.user}/users-dropdown`);
    }

}

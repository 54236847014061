import { Component, Input } from '@angular/core';
import {
    MapperMarketplace,
    PublishMarketplace,
    PublishMarketplace2LabelMapping
} from 'app/core/enums/feed/publish.marketplace';

@Component({
  selector: 'publish-marketplace',
  templateUrl: './publish-marketplace.component.html',
  styleUrls: ['./publish-marketplace.component.scss']
})
export class PublishMarketplaceComponent {


  @Input() parentFormGroup;
  @Input() selection: string;

  marketplace2LabelMapping = PublishMarketplace2LabelMapping;
  publishMarketplaces = Object.values(PublishMarketplace).filter(
    value => typeof value === 'number' && value !== PublishMarketplace.None
  );
  mapperMarketplaces = Object.values(MapperMarketplace).filter(
      value => typeof value === 'number'
  );
}

/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { FrontendApi } from '../../shared/frontend.api';
import { Account } from '../models/account';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    /**
     * Constructor
     */
    constructor(private router: Router, private _httpClient: HttpClient) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Add new account
     *
     * @param account
     */
    add(account: Account): Observable<any> {
        return this._httpClient.post(`${FrontendApi.account}`, account);
    }

    /**
     * Get all accounts
     *
     *
     */
    getAll(): Observable<any> {
        return this._httpClient.get<Account[]>(`${FrontendApi.account}`);
    }

    /**
     * Get all marketplace configs
     *
     *
     */
    getAllMarketplaceConfig(): Observable<any> {
        return this._httpClient.get<Account[]>(`${FrontendApi.account}/marketplace`);
    }

    /**
     * Get all accounts for view
     *
     *
     */
    getAllView(): Observable<any> {
        return this._httpClient.get<Account[]>(`${FrontendApi.account}/view`);
    }

    /**
     * Get all accounts dropdown list
     *
     *
     */
    getAllDropDownList(): Observable<any> {
        return this._httpClient.get<Account[]>(`${FrontendApi.account}/dropdownlist`);
    }

    /**
     * Get account by Id
     *
     * @param id
     */
    getById(id: string): Observable<Account> {
        return this._httpClient.get<Account>(`${FrontendApi.account}/${id}`);
    }

    /**
     * Update the account
     *
     * @param account
     */
    update(id: string, params: any): Observable<any> {
        return this._httpClient
            .put(`${FrontendApi.account}/${id}`, params)
            .pipe(
                map(x => x)
            );
    }

    /**
     * Delete the account
     *
     * @param id
     */
    delete(id: string): Observable<any> {
        return this._httpClient.delete(`${FrontendApi.account}/${id}`).pipe(
            map(x => x)
        );
    }

    /**
     * Get all accounts dropdown list for Tracking pixel
     */
    getAllTrackingPixelDropDownList(): Observable<any> {
        return this._httpClient.get<Account[]>(`${FrontendApi.account}/dropdownlist-tracking-pixel`);
    }

    refreshBingToken(): Observable<any> {
        return this._httpClient.get(`${FrontendApi.account}/refresh-bing-token`);
    }

    getBingAccessToken(accountId: string, code: string): Observable<any> {
        return this._httpClient.get(`${FrontendApi.account}/get-bing-refresh-token/${accountId}?code=${encodeURI(code)}`);
    }
}

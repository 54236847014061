/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Account } from 'app/core/models/account';
import { AccountService } from 'app/core/services/account.service';
import { HelperService } from 'app/shared/_services/helper.service';
import { first } from 'rxjs';

@Component({
  selector: 'accounts-dropdown',
  templateUrl: './accounts-dropdown.component.html',
  styleUrls: ['./accounts-dropdown.component.scss']
})
export class AccountsDropdownComponent implements OnInit {

  @Output() onSelected = new EventEmitter<any>();
  @Input() isHomeTask = false;
  accounts: Account[] = [];
  accountId = this._helperService.getDefaultAccount();
  backupAccountsList: Account[] = [];

  constructor(
    private _accountService: AccountService,
    private _helperService: HelperService) { }

  ngOnInit(): void {

    this._accountService
      .getAllDropDownList()
      .pipe(first())
      .subscribe(
        {
          next: (res) => {
            if (res.length > 0) {

              if (this.isHomeTask) {
                res.unshift({ accountId: '', accountName: 'Sub-domain' });
              }
              this.accounts = res;
              this.backupAccountsList = res;
            }
            else {
              const response = [];
              if (this.isHomeTask) {
                response.push({ accountId: '', accountName: 'Sub-domain' });
              }
              this.accounts = response;
            }
          },
          error: (err) => {
            console.log(err.error.message);
          }
        }
      );
  }

  changeAccount(event): void {
    if (event.value) {
      this._helperService.setDefaultAccount(event.value);
    }
    //this.accountId = event.value;
    this.onSelected.emit(event.value);
  }

  onKey(value): void {
    this.accounts = this.backupAccountsList;
    this.accounts = this.search(value);
    }

  search(value: string): Account[] {
    const filter = value.toLowerCase();
    return this.accounts.filter(option => option.accountName.toLowerCase().includes(filter));
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'delimiter-characters',
  templateUrl: './delimiter-characters.component.html',
  styleUrls: ['./delimiter-characters.component.scss']
})
export class DelimiterCharactersComponent {
  @Input() parentFormGroup;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum TokenisationLevel {
    Adgroup = 0,
    Campaign = 1,
}

export const TokenisationLevel2LabelMapping: Record<TokenisationLevel, string> = {
    [TokenisationLevel.Adgroup]: 'Adgroup',
    [TokenisationLevel.Campaign]: 'Campaign',
};

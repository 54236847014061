import { Component, Input } from '@angular/core';
import { JobStatus } from 'app/core/enums/job/job.status';

@Component({
  selector: 'view-report-button',
  templateUrl: './view-report-button.component.html',
  styleUrls: ['./view-report-button.component.scss']
})
export class ViewReportButtonComponent {
  @Input() element: any;
  @Input() toolTipText: string = 'View Report';
  jobStatus = JobStatus;
}

<div class="form-group" [formGroup]="parentFormGroup">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Marketplace</mat-label>
        <mat-select [formControlName]="'marketplace'">
            <mat-option
                *ngFor="let marketplace of marketplaces"
                [value]="marketplace"
                [disabled]="!isAvailable(marketplace)"
            >
                {{ marketplace2LabelMapping[marketplace] }}
            </mat-option>
        </mat-select>
        <mat-error
            *ngIf="parentFormGroup.get('marketplace').hasError('required')"
        >
            Marketplace is required
        </mat-error>
    </mat-form-field>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {IFilterOperator} from 'app/shared/_interfaces/filter-fields.interface';

@Pipe({
    name: 'operatorFilter',
    pure: false
})
export class OperatorFilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/ban-types
    transform(items: IFilterOperator[], typeFilter: string): any {
        if (!items) {
            return [];
        } else if (!typeFilter) {
            return items;
        }
        return items.filter(item => item.associatedTypes.includes(typeFilter));
    }
}

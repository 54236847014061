/* eslint-disable @typescript-eslint/naming-convention */
export enum DownloadMarketplace {
    dreamAgility = 0,
    maps = 1,
    bing = 2,
    google = 3,
    all = 4
}

export const DownloadMarketplace2LabelMapping: Record<DownloadMarketplace, string> = {
    [DownloadMarketplace.dreamAgility]: 'DreamAgility',
    [DownloadMarketplace.maps]: 'Maps',
    [DownloadMarketplace.bing]: 'Bing',
    [DownloadMarketplace.google]: 'Google',
    [DownloadMarketplace.all]: 'All',
};

/* eslint-disable @typescript-eslint/naming-convention */
export enum JobType {
    BiddingGenius = 0,
    Mlod = 1,
    ProductOptimiser = 2,
    ReverseMlod = 3,
    StructureBuilder = 4,
    Download = 5,
    Publish = 6,
    TopProductIdentifier = 7,
    FeedDownloadPublish = 8
}

export const JobType2LabelMapping: Record<JobType, string> = {
    [JobType.BiddingGenius]: 'Bidding Genius',
    [JobType.Mlod]: 'Mlod',
    [JobType.ProductOptimiser]: 'Product Optimiser',
    [JobType.ReverseMlod]: 'Reverse Mlod',
    [JobType.StructureBuilder]: 'Structure Builder',
    [JobType.Download]: 'Download',
    [JobType.Publish]: 'Publish',
    [JobType.TopProductIdentifier]: 'Top Product Identifier',
    [JobType.FeedDownloadPublish]: 'Feed Download Publish'
};

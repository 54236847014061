import { Component, Input } from '@angular/core';

@Component({
  selector: 'compress-feed-toggle',
  templateUrl: './compress-feed-toggle.component.html',
  styleUrls: ['./compress-feed-toggle.component.scss']
})
export class CompressFeedToggleComponent {
  @Input() parentFormGroup;
}

import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then(m => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then(m => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then(m => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        m => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        m => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        m => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then(m => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'landing',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        m => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'example',
                loadChildren: () =>
                    import('app/modules/admin/example/example.module').then(
                        m => m.ExampleModule
                    ),
            },
            {
                path: 'top_products_dashboard',
                loadChildren: () =>
                    import('app/modules/admin/dashboard/dashboard.module').then(
                        m => m.DashboardModule
                    ),
            },
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/admin/home/home.module').then(
                        m => m.HomeModule
                    ),
            },
            {
                path: 'accounts',
                loadChildren: () =>
                    import('app/modules/admin/accounts/accounts.module').then(
                        m => m.AccountsModule
                    ),
            },
            {
                path: 'feed_optimiser',
                loadChildren: () =>
                    import(
                        'app/modules/admin/feed-manager/feed-optimiser/feed-optimiser.module'
                    ).then(m => m.FeedOptimiserModule),
            },
            {
                path: 'feed_mapper/:mapId',
                loadChildren: () =>
                    import(
                        'app/modules/admin/feed-manager/feed-mapper/feed-mapper.module'
                    ).then(m => m.FeedMapperModule),
            },
            {
                path: 'feeds',
                loadChildren: () =>
                    import(
                        'app/modules/admin/feed-manager/feeds/feeds.module'
                    ).then(m => m.FeedsModule),
            },
            {
                path: 'feed_product_exclusions',
                loadChildren:()=>
                    import(
                        'app/modules/admin/feed-manager/excluded-products/excluded-products.module'
                    ).then(m => m.ExcludedProductsModule),
            },
            {
                path: 'product_search',
                loadChildren: () =>
                    import(
                        'app/modules/admin/feed-manager/product-search/product-search.module'
                    ).then(m => m.ProductSearchModule),
            },
            {
                path: 'batch_updates',
                loadChildren: () =>
                    import(
                        'app/modules/admin/feed-manager/product-batch-updates/product-batch-updates.module'
                    ).then(m => m.ProductBatchUpdatesModule),
            },
            {
                path: 'mlod',
                loadChildren: () =>
                    import('app/modules/admin/tools/mlod/mlod.module').then(
                        m => m.MlodModule
                    ),
            },
            {
                path: 'bidding_genius',
                loadChildren: () =>
                    import(
                        'app/modules/admin/tools/bidding-genius/bidding-genius.module'
                    ).then(m => m.BiddingGeniusModule),
            },
            {
                path: 'product_optimiser',
                loadChildren: () =>
                    import(
                        'app/modules/admin/tools/product-optimiser/product-optimiser.module'
                    ).then(m => m.ProductOptimiserModule),
            },
            {
                path: 'reverse_mlod',
                loadChildren: () =>
                    import(
                        'app/modules/admin/tools/reverse-mlod/reverse-mlod.module'
                    ).then(m => m.ReverseMlodModule),
            },
            {
                path: 'structure_builder',
                loadChildren: () =>
                    import(
                        'app/modules/admin/tools/structure-builder/structure-builder.module'
                    ).then(m => m.StructureBuilderModule),
            },
            {
                path: 'tracking_pixel',
                loadChildren: () =>
                    import(
                        'app/modules/admin/tools/tracking-pixel/tracking-pixel.module'
                    ).then(m => m.TrackingPixelModule),
            },
            // {
            //     path: 'feed_scheduler',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/admin/scheduler/feed-scheduler/feed-scheduler.module'
            //         ).then((m) => m.FeedSchedulerModule),
            // },
            {
                path: 'job_scheduler',
                loadChildren: () =>
                    import(
                        'app/modules/admin/scheduler/job-scheduler/job-scheduler.module'
                    ).then(m => m.JobSchedulerModule),
            },
            {
                path: 'scheduler_timeline',
                loadChildren: () =>
                    import(
                        'app/modules/admin/scheduler/scheduler-timeline/scheduler-timeline.module'
                    ).then(m => m.SchedulerTimelineModule),
            },
            {
                path: 'text_ads_scheduler',
                loadChildren: () =>
                    import(
                        'app/modules/admin/scheduler/text-ads-scheduler/text-ads-scheduler.module'
                    ).then(m => m.TextAdsSchedulerModule),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('app/modules/admin/users/users.module').then(
                        m => m.UsersModule
                    ),
            },
            {
                path: 'logs',
                loadChildren: () =>
                    import('app/modules/admin/logs/logs.module').then(
                        m => m.LogsModule
                    ),
            },
            {
                path: 'compliance-issues',
                loadChildren: () =>
                    import('app/modules/admin/compliance-issues/compliance.module').then(
                        m => m.ComplianceModule
                    ),
            },
            { path: 'user/settings', loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule) },
            { path:'feed_sdk_scripts', loadChildren: () => import('app/modules/admin/feed-manager/sdk-scripts/sdk-scripts.module').then(m => m.SdkScriptsModule) },
            { path:'redirect', loadChildren: () => import('app/modules/admin/redirect/redirect.module').then(m => m.RedirectModule) },
            //Users are redirected to this page when a 403 response is received
            {
                path: '403',
                loadChildren: () =>
                    import('app/modules/errors/internal-error403/internal-error403.module').then(
                        m => m.InternalError403Module
                    )
            },
            //Wildcard to redirect unknown url to 404 page if authenticated.
            {
                path: '**',
                loadChildren: () =>
                    import('app/modules/errors/internal-error404/internal-error404.module').then(
                        m => m.InternalError404Module
                    )
            },
        ],
    },
];

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFilterField, IFilterOperator, IFilterValue, IFilter } from 'app/shared/_interfaces/filter-fields.interface';

@Component({
    selector: 'app-filter-details',
    templateUrl: './filter-details.component.html',
    styleUrls: ['./filter-details.component.scss']
})
export class FilterDetailsComponent implements OnInit{

    fields: IFilterField[];
    filter: IFilter;
    // Every operator has to be UNIQUE
    allOperators: IFilterOperator[] = [
        {associatedTypes: ['string', 'number', 'boolean', 'set'], operator: '='},
        {associatedTypes: ['string', 'number', 'boolean', 'set'], operator: 'Not Equal'},
        {associatedTypes: ['string'], operator: 'Contains'},
        {associatedTypes: ['string'], operator: 'Not Contains'},
        {associatedTypes: ['string'], operator: 'Starts With'},
        {associatedTypes: ['string'], operator: 'Ends With'},
        {associatedTypes: ['number'], operator: '<'},
        {associatedTypes: ['number'], operator: '<='},
        {associatedTypes: ['number'], operator: '>='},
        {associatedTypes: ['number'], operator: '>'},
        {associatedTypes: ['date'], operator: 'Before'},
        {associatedTypes: ['date'], operator: 'On'},
        {associatedTypes: ['date'], operator: 'Between'},
        {associatedTypes: ['date'], operator: 'After'},
    ];
    currentSelectOptions: IFilterValue[]= [];
    isEditingFilter: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<FilterDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {fields: IFilterField[]; filter?: IFilter}
    ) {}

    ngOnInit(): void {
        this.fields = this.data.fields;

        if (this.data.filter) {
            this.isEditingFilter = true;
            this.filter = this.data.filter;
            this.generateValueSelectionOptions(this.filter.field);
        } else {
            this.filter = {
                field: null, operator: {associatedTypes: [], operator: null}, value: {value: null, displayText: null}
            };
        }
    }

    toggleCaseSensitivity(): void {
        this.filter.value.caseInsensitivity = (this.filter.value.caseInsensitivity === undefined ? true : !this.filter.value.caseInsensitivity);
    }

    generateValueSelectionOptions(field: IFilterField): void {
        if (field.type === 'set' && field.setOptions) {
            // eslint-disable-next-line max-len
            this.currentSelectOptions = field.setOptions.map(ele => ({displayText: typeof(ele) === 'object' ? ele.display : ele, value: typeof(ele) === 'object' ? ele.value : ele}));
        } else if (field.type === 'boolean' && field.booleanMappings) {
            this.currentSelectOptions = [
                {displayText: field.booleanMappings.true, value: 'true'},
                {displayText: field.booleanMappings.false, value: 'false'}
            ];
        }
    }

    fieldChange(newField: IFilterField): void {
        // Reset Operator and Value if no longer valid
        if (this.filter.operator.operator && !this.filter.operator.associatedTypes.includes(newField.type)) {
            this.filter.operator.operator = null;
            if (this.filter.value.value) {
                this.filter.value = {displayText: null, value: null};
            }
        }

        // Update Value selection options
        this.generateValueSelectionOptions(newField);
    }

    cancel(): void {
        this.dialogRef.close();
    }

    getDateStringFromDate(date: Date): string {
        return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
    }

    createFilter(): void {
        if (this.filter.value !== null) {
            if (this.filter.field.type === 'string'  || this.filter.field.type === 'number') {
                this.filter.value.displayText = this.filter.value.value.toString();
            } else if (this.filter.field.type === 'date') {
                if (this.filter.operator.operator !== 'Between') {
                    // @ts-ignore
                    this.filter.value.displayText = this.getDateStringFromDate(this.filter.value.value);
                } else {
                    this.filter.value.displayText = this.getDateStringFromDate(this.filter.value.startDate) + ' - ' + this.getDateStringFromDate(this.filter.value.endDate);
                }
            } else if (this.filter.field.type === 'set') {
                this.filter.value.displayText = this.filter.value.value.toString();
            } else if (this.filter.field.type === 'boolean') {
                // @ts-ignore
                this.filter.value.displayText = this.filter.field.booleanMappings[this.filter.value.value];
            } else {
                console.error('Unknown field type: ' + this.filter.field.type);
            }
        } else {
            console.log('Value is required');
        }

        this.dialogRef.close(this.filter);
    }
}

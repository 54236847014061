<div class="form-group" [formGroup]="parentFormGroup">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Limit Type</mat-label>
        <mat-select [formControlName]="'limitType'" #limitType>
            <mat-option
                *ngFor="let limitType of limitTypes"
                [value]="limitType"
            >
                {{ limitType2LabelMapping[limitType] }}
            </mat-option>
        </mat-select>
        <mat-error
            *ngIf="parentFormGroup.get('limitType').hasError('required')"
        >
            Limit Type is required
        </mat-error>
    </mat-form-field>

    <mat-form-field
        class="example-full-width"
        appearance="fill"
        *ngIf="limitType.value === 0"
    >
        <mat-label>Click Limit</mat-label>
        <input 
            id="clickLimit"
            type="number"
            min="0"
            matInput
            [formControlName]="'clickLimit'"
        />
    </mat-form-field>
    <p *ngIf="viewOnly && limitType.value === 0">Click Limit: {{threshold}} clicks</p>

    <div *ngIf="limitType.value === 1">
        <mat-slider 
            *ngIf = "isAdmin()"
            [formControlName]="'confidenceThreshold'"
            step="1"
            min="44"
            max="99"
            discrete
            thumbLabel
            [displayWith]="_helperService.formatConfidenceThresholdRequest"
            class="my-3"
        >
        </mat-slider>

        <ng-container
            *ngIf="isAdmin() === false"
        >
            <mat-slider
                [formControlName]="'confidenceThreshold'"
                step="1"
                min="1"
                max="15"
                discrete
                thumbLabel
                class="my-3"
            >
            </mat-slider>
            <p>Note: The higher the level number, the more agressive the push. </p>
        </ng-container>
        <p *ngIf="viewOnly">Confidence: {{threshold}}%</p>
    </div>
</div>

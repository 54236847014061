import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Account } from 'app/core/models/account';
import { AccountService } from 'app/core/services/account.service';
import { HelperService } from 'app/shared/_services/helper.service';
import { first } from 'rxjs';

@Component({
  selector: 'tracking-pixel-accounts-dropdown',
  templateUrl: './tracking-pixel-accounts-dropdown.component.html',
  styleUrls: ['./tracking-pixel-accounts-dropdown.component.scss']
})
export class TrackingPixelAccountsDropdownComponent implements OnInit {

  @Output() selected = new EventEmitter<any>();
  accounts: Account[] = [];
  accountId = this._helperService.getDefaultAccount();

  constructor(
    private _accountService: AccountService,
    private _helperService: HelperService) { }

  ngOnInit(): void {

    this._accountService
      .getAllTrackingPixelDropDownList()
      .pipe(first())
      .subscribe(
        {
          next: (res) => {
            if (res.length > 0) {
              this.accounts = res;
            }
          },
          error: (err) => {
            console.log(err.error.message);
          }
        }
      );
  }

  changeAccount(event): void {
    if (event.value) {
      this._helperService.setDefaultAccount(event.value);
    }
    this.selected.emit(event.value);
  }

}

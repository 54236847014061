import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseCardModule } from '@fuse/components/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { AlertModule } from './_modules/alert/alert.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableResponsiveModule } from './_directives/mat-table-responsive/mat-table-responsive.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CronEditorModule } from 'ngx-cron-editor';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from './date-formats';
import {MatGridListModule} from '@angular/material/grid-list';

//shared components
import { FiltersComponent } from './_components/filters/filters.component';
import { OperatorFilterPipe } from './_components/filters/filter-details/OperatorPipe';
import { FilterDetailsComponent } from './_components/filters/filter-details/filter-details.component';
import { MarketplaceComponent } from 'app/shared/_components/misc/marketplace/marketplace.component';
import { TokenisationLevelComponent } from 'app/shared/_components/tools/tokenisation-level/tokenisation-level.component';
import { LimitTypeComponent } from 'app/shared/_components/tools/limit-type/limit-type.component';
import { SetRoasComponent } from 'app/shared/_components/tools/set-roas/set-roas.component';
import { SetConversionValueComponent } from 'app/shared/_components/tools/set-conversion-value/set-conversion-value.component';
import { PushChangesComponent } from './_components/misc/push-changes/push-changes.component';
import { AccountsDropdownComponent } from './_components/misc/accounts-dropdown/accounts-dropdown.component';
import { CountryComponent } from './_components/misc/country/country.component';
//import { StructureTypeComponent } from './_components/tools/structure-builder/structure-type/structure-type.component';
import { CompressFeedToggleComponent } from './_components/feeds/compress-feed-toggle/compress-feed-toggle.component';
import { RoundPriceToggleComponent } from './_components/feeds/round-price-toggle/round-price-toggle.component';
import { CheckNewProductsToggleComponent } from './_components/feeds/check-new-products-toggle/check-new-products-toggle.component';
import { SkipCompliancyCheckComponent } from './_components/feeds/skip-compliancy-check/skip-compliancy-check.component';
import { PublishMarketplaceComponent } from './_components/feeds/publish-marketplace/publish-marketplace.component';
import { DelimiterCharactersComponent } from './_components/feeds/delimiter-characters/delimiter-characters.component';
import { FileExtensionTypeComponent } from './_components/feeds/file-extension-type/file-extension-type.component';
import { JobStatusComponent } from './_components/tools/job-status/job-status.component';
import { ViewReportButtonComponent } from './_components/tools/view-report-button/view-report-button.component';
import { MarketplaceIconComponent } from './_components/misc/marketplace-icon/marketplace-icon.component';
import { DownloadDialogComponent } from './_components/tools/tracking-pixel/download-dialog/download-dialog.component';
import { TrackingPixelAccountsDropdownComponent } from './_components/misc/tracking-pixel-accounts-dropdown/tracking-pixel-accounts-dropdown.component';
import { ReturnsFactorComponent } from './_components/tools/bidding-genius/returns-factor/returns-factor.component';
import { OfflineFactorComponent } from './_components/tools/bidding-genius/offline-factor/offline-factor.component';
import { ConversionValueOverrideComponent } from './_components/tools/bidding-genius/conversion-value-override/conversion-value-override.component';
import { DpPushStatusComponent } from './_components/tools/dp-push-status/dp-push-status.component';
import { DpMatchTypeComponent } from './_components/tools/dp-match-type/dp-match-type.component';
import { AdGroupLabelsComponent } from './_components/tools/ad-group-labels/ad-group-labels.component';


//Bidding Genius Reports
//import { AccountProfitLossReportComponent } from './_components/tools/bidding-genius/account-profit-loss-report/account-profit-loss-report.component';
//import { AdProfitLossReportComponent } from './_components/tools/bidding-genius/ad-profit-loss-report/ad-profit-loss-report.component';
import { ProductReportComponent } from './_components/tools/bidding-genius/product-report/product-report.component';
//import { ProductOverviewReportComponent } from './_components/tools/bidding-genius/product-overview-report/product-overview-report.component';
//import { ProductTitleAnalysisReportComponent } from './_components/tools/bidding-genius/product-title-analysis-report/product-title-analysis-report.component';
import { TextAdsKeywordReportComponent } from './_components/tools/bidding-genius/text-ads-keyword-report/text-ads-keyword-report.component';
import { SearchTermReportComponent } from './_components/tools/bidding-genius/search-term-report/search-term-report.component';
import { BagOfWordsReportComponent } from './_components/tools/bidding-genius/bag-of-words-report/bag-of-words-report.component';
//import { RslaAudienceReportComponent } from './_components/tools/bidding-genius/rsla-audience-report/rsla-audience-report.component';

//Pipes
import { TruncateTextPipe } from './_pipes/truncate-text.pipe';
import { DecimalFormatPipe } from './_pipes/decimal-format.pipe';


const sharedModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FuseCardModule,
  MatIconModule,
  MatMenuModule,
  MatFormFieldModule,
  MatTableModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  FuseAlertModule,
  MatDialogModule,
  MatSelectModule,
  MatTooltipModule,
  MatDividerModule,
  FuseConfirmationModule,
  AlertModule,
  MatRadioModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatSliderModule,
  MatButtonToggleModule,
  MatSortModule,
  MatTableResponsiveModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  FontAwesomeModule,
  MatPaginatorModule,
  CronEditorModule,
  MatListModule,
  MatTabsModule,
  RouterModule,
  MatGridListModule
];

const sharedComponents = [
  FilterDetailsComponent,
  MarketplaceComponent,
  TokenisationLevelComponent,
  LimitTypeComponent,
  SetRoasComponent,
  SetConversionValueComponent,
  PushChangesComponent,
  AccountsDropdownComponent,
  CountryComponent,
  //AccountProfitLossReportComponent,
  //AdProfitLossReportComponent,
  ProductReportComponent,
  //ProductOverviewReportComponent,
  //ProductTitleAnalysisReportComponent,
  TextAdsKeywordReportComponent,
  SearchTermReportComponent,
  BagOfWordsReportComponent,
  //RslaAudienceReportComponent,
  PublishMarketplaceComponent,
  FiltersComponent,
  OperatorFilterPipe,
  CompressFeedToggleComponent,
  RoundPriceToggleComponent,
  CheckNewProductsToggleComponent,
  SkipCompliancyCheckComponent,
  DelimiterCharactersComponent,
  FileExtensionTypeComponent,
  JobStatusComponent,
  ViewReportButtonComponent,
  MarketplaceIconComponent,
  DownloadDialogComponent,
  TrackingPixelAccountsDropdownComponent,
  ReturnsFactorComponent,
  OfflineFactorComponent,
  ConversionValueOverrideComponent,
  DpPushStatusComponent,
  DpMatchTypeComponent,
  AdGroupLabelsComponent
];

const sharedPipes = [
  TruncateTextPipe,
  DecimalFormatPipe
];

@NgModule({
  imports: [sharedModules],
  exports: [
    sharedModules,
    sharedComponents,
    sharedPipes
  ],
  declarations: [sharedComponents, sharedPipes],
  providers: [
    DecimalPipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class SharedModule { }

import { Component, Input } from '@angular/core';

@Component({
  selector: 'dp-push-status',
  templateUrl: './dp-push-status.component.html',
  styleUrls: ['./dp-push-status.component.scss']
})
export class DpPushStatusComponent {
@Input() pushStatus: boolean;
}

<div class="px-3 mb-1 form-group" [formGroup]="parentFormGroup">
    <div class="flex flex-wrap w-full flex items-center">
        <mat-label class="mb-2">Use Returns Factor (% of sales returned): </mat-label>
        <mat-button-toggle-group [formControlName]="'useReturnsFactor'" #useReturnsFactorToggleGroup="matButtonToggleGroup" class="mb-2 flex-wrap">
            <mat-button-toggle *ngIf="checkDisplayOfReturns('useReturnsFactor', returnsFactorType.Off)" [value]="returnsFactorType.Off">{{returnsFactorType2LabelMapping[returnsFactorType.Off]}}</mat-button-toggle>
            <mat-button-toggle *ngIf="checkDisplayOfReturns('useReturnsFactor', returnsFactorType.AddAdditionalCostPerReturn)" [value]="returnsFactorType.AddAdditionalCostPerReturn">{{returnsFactorType2LabelMapping[returnsFactorType.AddAdditionalCostPerReturn]}}</mat-button-toggle>
            <mat-button-toggle *ngIf="checkDisplayOfReturns('useReturnsFactor', returnsFactorType.NoAdditionalCost)" [value]="returnsFactorType.NoAdditionalCost">{{returnsFactorType2LabelMapping[returnsFactorType.NoAdditionalCost]}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div [hidden]="parentFormGroup.get('useReturnsFactor').value !== 2">
        <mat-form-field>
            <input style="text-align: right"  matInput [formControlName]="'returnsFactor'" type="number" min="0" oninput="validity.valid||(value=0);">
            <p>%</p>
        </mat-form-field>
    </div>
</div>
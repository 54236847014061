<div class="px-3 mb-2" [formGroup]="parentFormGroup">
    <mat-label>Use Offline Factor (+% of sales): </mat-label>
    <mat-button-toggle-group [formControlName]="'useOfflineFactor'" #useOfflineFactorToggleGroup="matButtonToggleGroup" class="mb-2">
        <mat-button-toggle *ngIf="checkDisplayOfValueForm('useOfflineFactor', true)" [value]="true">Yes</mat-button-toggle>
        <mat-button-toggle *ngIf="checkDisplayOfValueForm('useOfflineFactor', false)" [value]="false">No</mat-button-toggle>
    </mat-button-toggle-group>
    <div [hidden]="parentFormGroup.get('useOfflineFactor').value !== true">
        <div class="flex flex-wrap w-full items-baseline">
            <label *ngIf="configForm === true" id="currencyLabelOffline" class="w-full md:w-1/3 font-extrabold">{{currency}}</label>

            <mat-form-field *ngIf="configForm === false" class="w-full md:w-1/3">
                <mat-select [disabled]="configForm" placeholder="Currency">
                    <div class="sticky-input-container w-full">
                        <input
                            placeholder="Click here to search"
                            (keyup)="onKey($event.target.value)" 
                            (click)="$event.stopPropagation()"          
                            (keydown)="$event.stopPropagation()"
                            class="px-3 h-7"
                        >
                        <!-- stop propagation to allow for keys like space to be pressed without triggering options -->
                    </div>
                    <mat-option *ngFor="let currency of currencyCodes" [value]="currency">{{currency}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full md:w-2/3">
                <p>+</p>
                <input style="text-align: right" matInput [formControlName]="'offlineFactor'" type="number" min="0" oninput="validity.valid||(value=0);">
                <p>%</p>
            </mat-form-field>
        </div>

    </div>
</div>
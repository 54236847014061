/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {ReturnsFactorType, ReturnsFactorType2LabelMapping} from '../../../../../core/enums/job/returns.factor.type';

@Component({
  selector: 'product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.scss']
})
export class ProductReportComponent implements OnInit {

  @Output() onFormChange = new EventEmitter();

  @Input() config: any;
  @Input() viewOnly: any;
  @Input() currency: string;

  @ViewChild('productNgForm') productNgForm: NgForm;
  productForm: UntypedFormGroup;

  configForm: boolean;

  returnsFactorType = ReturnsFactorType;
  returnsFactorType2LabelMapping = ReturnsFactorType2LabelMapping;

  constructor(private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    const configPresent: boolean = this.config ? true: false;
    this.configForm = configPresent;

    this.productForm = this._formBuilder.group({
      clickLimit:[{value: 0, disabled: this.viewOnly}, []],
      lossLimit:[{value: 0, disabled: this.viewOnly}, []],
      defaultCpc:[{value: 0, disabled: this.viewOnly}, []],
      grossMargin:[{value: 0, disabled: this.viewOnly}, []],
      additionalCost:[{value: 0, disabled: this.viewOnly}, []],
      netMarginTarget:[{value: 0, disabled: this.viewOnly}, []],
      maxCpc:[{value: 0, disabled: this.viewOnly}, []],
      useConversionValueOverride:[{value: false, disabled: this.viewOnly}, []],
      conversionValueOverride:[{value: 0, disabled: this.viewOnly}, []],
      useOfflineFactor:[{value: false, disabled: this.viewOnly}, []],
      offlineFactor:[{value: 0, disabled: this.viewOnly}, []],
      useReturnsFactor:[{value: ReturnsFactorType.Off, disabled: this.viewOnly}, []],
      returnsFactor:[{value: 0, disabled: this.viewOnly}, []]
    });

    if(configPresent){
      const reportFields: object = this.config.productReportFields;
      this.productForm.patchValue(reportFields);
    }

    this.productForm.valueChanges.subscribe((val)=>{
      val.grossMargin /= 100;
      val.netMarginTarget /= 100;
      this.onFormChange.emit({productReportFields:val});
    });

    this.onFormChange.emit({productReportFields:this.productForm.value});
  }

  setBoolVal(field: string, value: boolean): void{
    this.productForm.get(field).setValue(value);
  }

  checkDisplay(field: string, truthValue: string): boolean{
    return !this.configForm || this.productForm.get(field).value === truthValue;
  }

  calculateRoas(returnSpend: boolean): any{
    const grossMargin = this.productForm.get('grossMargin').value;

    const netMarginTarget = this.productForm.get('netMarginTarget').value;

    if(returnSpend){
      return grossMargin - netMarginTarget;
    }
    else{
      return (100/(grossMargin-netMarginTarget)).toFixed(2);
    }
  }
}

<!-- <form [formGroup]="filterForm" #filterNgForm="ngForm"> -->
    <h2 mat-dialog-title>Filter Options</h2>

    <!--Filter fields-->
    <div class="flex flex-wrap w-full">
        <mat-form-field 
            class="px-3">
            <mat-label>Field</mat-label>
            <mat-select 
                placeholder="Field" 
                (ngModelChange)="fieldChange($event)" 
                [(ngModel)]="filter.field">
                <mat-option 
                    [value]="field" 
                    *ngFor="let field of fields">
                        {{field.displayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!--Filter operation-->
    <div class="flex flex-wrap w-full">
        <mat-form-field 
            class ="px-3">
            <mat-label>Filter Operation</mat-label>
            <mat-select 
                placeholder="Filter Operation" 
                [disabled]="!filter.field" 
                [(ngModel)]="filter.operator.operator">
                <mat-option 
                    [value]="operator.operator" 
                    *ngFor="let operator of allOperators | operatorFilter: filter.field ? filter.field.type : undefined">
                        {{ operator.operator }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Set/Boolean -->
    <div 
        class="flex flex-wrap w-full" 
        *ngIf="filter.field && (filter.field.type === 'set' || filter.field.type === 'boolean')">
        <mat-form-field 
            class ="px-3">
            <mat-label>Value</mat-label>
            <mat-select 
                [disabled]="!filter.operator.operator"
                placeholder="Value" 
                [(ngModel)]="filter.value.value">
                <mat-option 
                    [value]="option.value" 
                    *ngFor="let option of currentSelectOptions">
                        {{option.displayText}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Date - Single -->
    <div 
        class="flex flex-wrap w-full" 
        *ngIf="filter.field && filter.field.type === 'date' && ((!this.filter.operator) || this.filter.operator.operator !== 'Between')">
        <mat-form-field 
            class ="px-3">
            <mat-label>Value</mat-label>
            <input 
                matInput 
                [disabled]="!filter.operator.operator"
                [matDatepicker]="picker" 
                [(ngModel)]="filter.value.value" 
                (click)="picker.open()" 
                style="flex-grow: 1">
                <div 
                    style="flex-shrink: 0">
                    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                </div>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <!-- Date - Multi -->
    <div 
        class="flex flex-wrap w-full" 
        *ngIf="filter.field && filter.field.type === 'date' && filter.operator && filter.operator.operator === 'Between'">
        <mat-form-field class ="px-3">
            <mat-label>Value</mat-label>
            <mat-date-range-input 
                mat-date-range-input 
                [rangePicker]="rangePicker" 
                style="flex-grow: 1">
                    <input 
                        matStartDate 
                        placeholder="Start date" 
                        (click)="rangePicker.open()" 
                        [(ngModel)]="filter.value.startDate">
                    <input  
                        matEndDate 
                        placeholder="End date" 
                        (click)="rangePicker.open()" 
                        [(ngModel)]="filter.value.endDate">
            </mat-date-range-input>
            <div 
                style="flex-shrink: 0">
                <mat-datepicker-toggle 
                    [for]="rangePicker">
                </mat-datepicker-toggle>
            </div>
            <mat-date-range-picker #rangePicker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <!-- String/Number/Default -->
    <div class="flex flex-wrap w-full" *ngIf="!filter.field || (filter.field.type === 'string' || filter.field.type === 'number')">
        <div class="w-full md:w-1/2 px-3">
            <mat-form-field>
                <mat-label>Value</mat-label>
                <input 
                    placeholder="Value" 
                    matInput 
                    [disabled]="!filter.operator.operator" 
                    [(ngModel)]="filter.value.value">
            </mat-form-field>
        </div>
        <div class="flex md:w-1/2 px-3 items-center justify-center align-middle" *ngIf="filter.field && filter.field.type === 'string'" >
            <mat-label class="px-5 text-lg">Case Sensitive:</mat-label>  
            <mat-checkbox 
                [checked] = "true"
                (click)="toggleCaseSensitivity()"
                [matTooltip]="filter.value.caseInsensitivity ? 'Case Sensitivity: Off' : 'Case Sensitivity: On'"
                >
            </mat-checkbox>
        </div>
    </div>

    <div id="buttons" class="row mt-5">
        <button
            class="mat-focus-indicator mat-button-base mat-action mat-accent" 
            mat-raised-button 
            mat-dialog-close 
            (click)="cancel()">
                Cancel
        </button>
        <button 
            class="mat-focus-indicator mat-button-base mat-action mat-primary" 
            mat-raised-button 
            (click)="createFilter()">
                {{isEditingFilter ? 'Update' : 'Add'}} Filter
        </button>
    </div>
<!-- </form> -->


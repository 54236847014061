import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AlertService } from 'app/shared/_services';
import { Constants } from '../config/app.contants';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private _alertService: AlertService, private router: Router) { }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (
            this._authService.accessToken &&
            !AuthUtils.isTokenExpired(this._authService.accessToken)
        ) {
            newReq = req.clone({
                headers: req.headers.set(
                    'Authorization',
                    'Bearer ' + this._authService.accessToken
                ),
            });
        } else {
            console.log('Token is expired');
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                console.log(error);

                this._alertService.clear();
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    if (!error.url.includes('authentication')) {
                        // Sign out
                        this._authService.signOut();

                        // Reload the app
                        location.reload();
                    }
                }
                else if (error instanceof HttpErrorResponse && error.status === 403) {
                    this._alertService.error('You don\'t have permission');
                    this.router.navigateByUrl('/403');

                }
                else if (error instanceof HttpErrorResponse && error.status === 400) {
                    if (error.error && error.error.errors) {
                        for (const key in error.error.errors) {
                            if (error.error.errors.hasOwnProperty(key) && key !== 'value') {
                                console.log('Validation error for', key, ':', error.error.errors[key]);
                                const errorMessageArray = error.error.errors[key];
                                const errorMessage = errorMessageArray[0]; // Get the first element of the array
                                console.log('Error Message: ' + errorMessage);
                                this._alertService.error(errorMessage);
                            }
                        }
                    } else if(error.error && error.error.message) {
                        this._alertService.error(error.error.message);
                    }
                }
                else if (error instanceof HttpErrorResponse && error.status === 409) {
                    if (error.error && error.error.message) {
                        this._alertService.error(error.error.message);
                    }
                }
                else if (error instanceof HttpErrorResponse && error.status === 0) {
                    this._alertService.error('Server is not responding...');
                }

                return throwError(() => error);
            })
        );
    }
}

<div class="form-group" [formGroup]="parentFormGroup">
    <div class="my-3">
        <mat-label class="font-medium"
            >Set Conversion Value Override?</mat-label
        >
        <mat-button-toggle-group
            value="no"
            [formControlName]="'useConversionValueOverride'"
            #conversionToggleGroup="matButtonToggleGroup"
        >
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <mat-form-field
        class="example-full-width"
        appearance="fill"
        *ngIf="conversionToggleGroup.value === true"
    >
        <mat-label>Conversion Value</mat-label>
        <input
            id="conversionOverrideValue"
            type="number"
            min="0"
            matInput
            [formControlName]="'conversionOverrideValue'"
        />
    </mat-form-field>
</div>

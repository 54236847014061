import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        if (error.status === 403) {
            Sentry.captureMessage('403 Forbidden Error');
        } else {
            Sentry.captureException(error);
        }
        console.error(error);
    }
}

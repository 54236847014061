import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { HelperService } from 'app/shared/_services/helper.service';

@Component({
  selector: 'push-changes',
  templateUrl: './push-changes.component.html',
  styleUrls: ['./push-changes.component.scss']
})
export class PushChangesComponent implements OnInit {
  @Input() parentFormGroup;
  @Input() disabled: boolean;

  isAdminOrManager: boolean;

  constructor(
    private _helperService: HelperService,
    private _authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.isAdminOrManager = this._helperService.isAdminOrManager(this._authService.accessToken);
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'round-price-toggle',
  templateUrl: './round-price-toggle.component.html',
  styleUrls: ['./round-price-toggle.component.scss']
})
export class RoundPriceToggleComponent {
  @Input() parentFormGroup;
}

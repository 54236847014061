import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'set-roas',
  templateUrl: './set-roas.component.html',
  styleUrls: ['./set-roas.component.scss']
})
export class SetRoasComponent {

  @Input() parentFormGroup;
  @Input() required: boolean;
  @Output() checkRoas = new EventEmitter();

  check(): void{
    this.checkRoas.emit();
  }
}

<div class="form-group" [formGroup]="parentFormGroup">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Tokenisation Level</mat-label>
        <mat-select [formControlName]="'tokenisationLevel'">
            <mat-option
                *ngFor="let tokenisationLevel of tokenisationLevels"
                [value]="tokenisationLevel"
            >
                {{ tokenisationLevel2LabelMapping[tokenisationLevel] }}
            </mat-option>
        </mat-select>
        <mat-error
            *ngIf="
                parentFormGroup.get('tokenisationLevel').hasError('required')
            "
        >
            Tokenisation Level is required
        </mat-error>
    </mat-form-field>
</div>

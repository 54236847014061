import { Component, Input } from '@angular/core';
import { TokenisationLevel, TokenisationLevel2LabelMapping } from 'app/core/enums/job/tokenisation.level';

@Component({
  selector: 'tokenisation-level',
  templateUrl: './tokenisation-level.component.html',
  styleUrls: ['./tokenisation-level.component.scss']
})
export class TokenisationLevelComponent {

  @Input() parentFormGroup;
  tokenisationLevel2LabelMapping = TokenisationLevel2LabelMapping;
  tokenisationLevels = Object.values(TokenisationLevel).filter(
    value => typeof value === 'number'
  );

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'conversion-value-override',
  templateUrl: './conversion-value-override.component.html',
  styleUrls: ['./conversion-value-override.component.scss']
})
export class ConversionValueOverrideComponent implements OnInit{
  @Input() parentFormGroup;
  @Input() configForm: boolean;
  @Input() currency: string;


   currencyCodes: string  [] =
   [
    'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN',
    'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL',
    'BSD', 'BTN', 'BWP', 'BYN', 'BZD',
    'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CUP', 'CVE', 'CZK',
    'DJF', 'DKK', 'DOP', 'DZD',
    'EGP', 'ERN', 'ETB', 'EUR',
    'FJD', 'FKP',
    'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD',
    'HKD', 'HNL', 'HRK', 'HTG', 'HUF',
    'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK',
    'JMD', 'JOD', 'JPY',
    'KES', 'KGS', 'KHR', 'KID', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT',
    'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD',
    'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN',
    'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD',
    'OMR',
    'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG',
    'QAR',
    'RON', 'RSD', 'RUB', 'RWF',
    'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'SSP', 'STN', 'SYP', 'SZL',
    'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TVD', 'TWD', 'TZS',
    'UAH', 'UGX', 'USD', 'UYU', 'UZS',
    'VES', 'VND', 'VUV',
    'WST',
    'XAF', 'XCD', 'XOF', 'XPF',
    'YER',
    'ZAR', 'ZMW'
   ];

   currencyCodesBackup: string[] = [];
   //Currency code selector to be removed when we switch to account based currency code


  ngOnInit(): void{
    this.currencyCodesBackup = this.currencyCodes;
  }

  checkDisplayOfValueForm(field: string, truthValue: boolean): boolean
  {
      return !this.configForm || this.parentFormGroup.get(field).value === truthValue;
  }

  onKey(value): void {
    this.currencyCodes = this.currencyCodesBackup;
    this.currencyCodes = this.search(value);
    }

  search(value: string): string[] {
    const filter = value.toLowerCase();
    return this.currencyCodes.filter(option => option.toLowerCase().includes(filter));
  }
}

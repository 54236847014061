<div class="form-group" [formGroup]="parentFormGroup">
    <mat-form-field appearance="fill">
        <mat-label>Country</mat-label>
        <mat-select [formControlName]="'country'" disableOptionCentering>
            <div class="sticky-input-container">
                <input
                    placeholder="Type here to search"
                    (keyup)="onKey($event.target.value)" 
                    (click)="$event.stopPropagation()"          
                    (keydown)="$event.stopPropagation()"
                    class="px-3 h-7"
                >
                <!-- stop propagation to allow for keys like space to be pressed without triggering options -->
            </div>
            <mat-option
                *ngFor="let country of countries"
                [value]="country"
            >
                {{ country2LabelMapping[country] }}
            </mat-option>
        </mat-select>
        <mat-error
            *ngIf="parentFormGroup.get('country').hasError('required')"
        >
            Country is required
        </mat-error>
    </mat-form-field>
</div>
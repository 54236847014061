/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReturnsFactorType, ReturnsFactorType2LabelMapping } from 'app/core/enums/job/returns.factor.type';

@Component({
  selector: 'search-term-report',
  templateUrl: './search-term-report.component.html',
  styleUrls: ['./search-term-report.component.scss']
})
export class SearchTermReportComponent implements OnInit {

  @Output() onFormChange = new EventEmitter();

  @Input() config: any;
  @Input() viewOnly: any;
  @Input() currency: string;

  @ViewChild('searchTermNgForm') searchTermNgForm: NgForm;
  searchTermForm: UntypedFormGroup;

  configForm: boolean;

  protected readonly returnsFactorType = ReturnsFactorType;
  protected readonly returnsFactorType2LabelMapping = ReturnsFactorType2LabelMapping;

  constructor(private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    const configPresent: boolean = this.config ? true: false;
    this.configForm = configPresent;

    this.searchTermForm = this._formBuilder.group({
      profitTarget:[{value: 0, disabled: this.viewOnly},[]],
      clickLimit:[{value: 0, disabled: this.viewOnly},[]],
      lossLimit:[{value: 0, disabled: this.viewOnly},[]],
      grossMargin:[{value: 0, disabled: this.viewOnly}, []],
      additionalCost:[{value: 0, disabled: this.viewOnly}, []],
      useConversionValueOverride:[{value: false, disabled: this.viewOnly}, []],
      conversionValueOverride:[{value: 0, disabled: this.viewOnly}, []],
      useOfflineFactor:[{value: false, disabled: this.viewOnly}, []],
      offlineFactor:[{value: 0, disabled: this.viewOnly}, []],
      useReturnsFactor:[{value: ReturnsFactorType.Off, disabled: this.viewOnly}, []],
      returnsFactor:[{value: 0, disabled: this.viewOnly}, []]
    });

    if(configPresent){
      this.configForm = true;
      const reportFields: object = this.config.searchTermReportFields;
      this.searchTermForm.patchValue(reportFields);
    }

    this.searchTermForm.valueChanges.subscribe((val)=>{
      val.grossMargin /= 100;
      this.onFormChange.emit({searchTermReportFields:val});
    });
    this.onFormChange.emit({searchTermReportFields:this.searchTermForm.value});
  }

  setBoolVal(field: string, value: boolean): void{
    this.searchTermForm.get('useConversionValueOverride').setValue(value);
  }
  checkDisplay(field: string, truthValue: string): boolean{
    return !this.configForm || this.searchTermForm.get(field).value === truthValue;
  }

  checkDisplayOfReturns(field: string, truthValue: ReturnsFactorType): boolean
  {
    return !this.configForm || this.searchTermForm.get(field).value === truthValue;
  }

}

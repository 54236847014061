import { Component, Input } from '@angular/core';

@Component({
  selector: 'set-conversion-value',
  templateUrl: './set-conversion-value.component.html',
  styleUrls: ['./set-conversion-value.component.scss']
})
export class SetConversionValueComponent {

  @Input() parentFormGroup;

}

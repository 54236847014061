/* eslint-disable @typescript-eslint/naming-convention */
export enum CriterionMatchType {
    Exact = 0,
    Phrase = 1,
    Broad = 2,
}

export const CriterionMatchType2LabelMapping: Record<CriterionMatchType, string> = {
    [CriterionMatchType.Exact]: 'Exact',
    [CriterionMatchType.Phrase]: 'Phrase',
    [CriterionMatchType.Broad]: 'Broad',
};

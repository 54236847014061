<h2 mat-dialog-title>Download</h2>
<alert></alert>
<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Marketplace</mat-label>
        <mat-select 
        placeholder="Select a marketplace"
        [(value)]="selectedMarketplace"
        >
            <mat-option
                *ngFor="let marketplace of marketplaces"
                [value]="marketplace"
            >
                {{ marketplace2LabelMapping[marketplace] }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<div class="grid justify-items-end">    
    <mat-dialog-actions>
        <button class="mat-focus-indicator mat-button-base mat-action mat-accent" mat-raised-button mat-dialog-close>Cancel</button>
        <button class="mat-focus-indicator mat-button-base mat-action mat-primary" mat-raised-button (click)="onDownload()">Download</button>
    </mat-dialog-actions>
</div>

<div class="form-group mt-4" [formGroup]="parentFormGroup">
    <mat-slide-toggle
        [color]="'primary'"
        [formControlName]="'CheckNewProducts'"
    >
        Check for New Products
    </mat-slide-toggle>
</div>
<div
    *ngIf="parentFormGroup.get('CheckNewProducts').value === true"
    class="mt-4"
>
    <div class="form-group" [formGroup]="parentFormGroup">
        <mat-form-field>
            <mat-label>Unique Field to Match</mat-label>
            <input matInput [formControlName]="'UniqueFieldToMatch'" />
            <mat-error
                *ngIf="
                    parentFormGroup
                        .get('UniqueFieldToMatch')
                        .hasError('required')
                "
            >
                Unique Field to Match is required
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-group" [formGroup]="parentFormGroup">
        <mat-form-field>
            <mat-label>Recipient Email(s) (Comma Seprated)</mat-label>
            <input matInput [formControlName]="'RecipientEmails'" />
            <mat-error
                *ngIf="
                    parentFormGroup.get('RecipientEmails').hasError('required')
                "
            >
                Recipient Email is required
            </mat-error>
        </mat-form-field>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { LimitType, LimitType2LabelMapping } from 'app/core/enums/job/limit.type';
import { HelperService } from 'app/shared/_services/helper.service';

@Component({
  selector: 'limit-type',
  templateUrl: './limit-type.component.html',
  styleUrls: ['./limit-type.component.scss']
})
export class LimitTypeComponent {

  @Input() parentFormGroup;
  @Input() viewOnly: boolean;
  @Input() threshold: any;

  limitType2LabelMapping = LimitType2LabelMapping;
  limitTypes = Object.values(LimitType).filter(
    value => typeof value === 'number'
  );

  constructor(
    private _helperService: HelperService,
    private _authService: AuthService
  ) {

  }

  isAdmin(): boolean {
    return this._helperService.isAdmin(this._authService.accessToken);
  }

}

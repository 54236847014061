import { Component, Input, OnInit } from '@angular/core';
import { JobStatus2LabelMapping } from 'app/core/enums/job/job.status';

@Component({
  selector: 'job-status',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss']
})
export class JobStatusComponent implements OnInit{
  @Input() element: any;
  @Input() customTooltip: string;

  switcher: string;
  jobStatus2LabelMapping = JobStatus2LabelMapping;

  ngOnInit(): void {
    this.switcher = this.jobStatus2LabelMapping[this.element.jobStatus];
  }
}

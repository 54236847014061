import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../_services/helper.service';

@Pipe({
  name: 'truncateText',
  pure: true,
})
export class TruncateTextPipe implements PipeTransform {
  constructor(private helperService: HelperService) {}

  transform(text: string, length: number): string {
    return this.helperService.truncateText(text, length);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum PublishMarketplace {
    Amazon = 0,
    Bing = 1,
    Facebook = 2,
    Google = 3,
    GoogleLocal = 4,
    GoogleLocalInventory = 5,
    GooglePromotions = 6,
    DreamAgilityMall = 7,
    None = 8
}

export enum MapperMarketplace {
    Amazon = 0,
    Bing = 1,
    Facebook = 2,
    Google = 3,
    GoogleLocal = 4,
    GoogleLocalInventory = 5,
    GooglePromotions = 6,
    DreamAgilityMall = 7,
}

export const PublishMarketplace2LabelMapping: Record<PublishMarketplace, string> = {
    [PublishMarketplace.Amazon]: 'Amazon',
    [PublishMarketplace.Bing]: 'Bing',
    [PublishMarketplace.Facebook]: 'Facebook',
    [PublishMarketplace.Google]: 'Google',
    [PublishMarketplace.GoogleLocal]: 'Google Local',
    [PublishMarketplace.GoogleLocalInventory]: 'Google Local Inventory',
    [PublishMarketplace.GooglePromotions]: 'Google Promotions',
    [PublishMarketplace.DreamAgilityMall]: 'DreamAgility Mall',
    [PublishMarketplace.None]: 'None',
};

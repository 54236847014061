<div class="px-3 mb-2" [formGroup]="parentFormGroup">
    <mat-label>Use Conversion Value: </mat-label>
    <mat-button-toggle-group [formControlName]="'useConversionValueOverride'" #useConversionValueOverrideToggleGroup="matButtonToggleGroup" class="mb-2">
        <mat-button-toggle *ngIf="checkDisplayOfValueForm('useConversionValueOverride', true)" [value]="true" >Yes</mat-button-toggle>
        <mat-button-toggle *ngIf="checkDisplayOfValueForm('useConversionValueOverride', false)" [value]="false" >No</mat-button-toggle>
    </mat-button-toggle-group>
    <div [hidden]="parentFormGroup.get('useConversionValueOverride').value !== true">
        <div class="flex flex-wrap w-full items-baseline">
            <mat-label *ngIf="configForm === true" id="currencyLabelConversion" class="w-full md:w-1/3 font-extrabold">{{currency}}</mat-label>

            <mat-form-field *ngIf="configForm === false" class="w-full md:w-1/3">
                <mat-select [disabled]="configForm" placeholder="Currency">
                    <div class="sticky-input-container w-full">
                        <input
                            placeholder="Click here to search"
                            (keyup)="onKey($event.target.value)" 
                            (click)="$event.stopPropagation()"          
                            (keydown)="$event.stopPropagation()"
                            class="px-3 h-7"
                        >
                        <!-- stop propagation to allow for keys like space to be pressed without triggering options -->
                    </div>
                    <mat-option *ngFor="let currency of currencyCodes" [value]="currency">{{currency}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full md:w-2/3">
                <input matInput [formControlName]="'conversionValueOverride'" type="number">
            </mat-form-field>
        </div>
    </div>
</div>
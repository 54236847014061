
<mat-form-field style="margin-bottom: -1.25em" class="md:w-72">
    <mat-select
        [(value)]="accountId"
        placeholder="Select an account"
        (selectionChange)="changeAccount($event)"
        #accountsdropdown
    >
        <mat-option
            *ngFor="let account of accounts"
            [value]="account.accountId"
        >
            {{ account.accountName }}
        </mat-option>
    </mat-select>
</mat-form-field>

<form [formGroup]="productForm" #productNgForm="ngForm" class="flex flex-col">
    <div class="flex flex-wrap w-full">
        <div class="w-full md:w-1/3 px-3 mt-3">
            <mat-form-field>
                <mat-label>Click Limit</mat-label>
                <input matInput type="number" [formControlName]="'clickLimit'">
            </mat-form-field>
        </div>
        <div class="w-full md:w-1/3 px-3 mt-3">
            <mat-form-field>
                <mat-label>Loss Limit</mat-label>
                <input matInput type="number" [formControlName]="'lossLimit'">
            </mat-form-field>
        </div>
        <div class="w-full md:w-1/3 px-3 mt-3">
            <mat-form-field>
                <mat-label>Default CPC</mat-label>
                <input matInput type="number" [formControlName]="'defaultCpc'">
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-col" style="margin-top:1em;margin-bottom:1em;">
        <span><b>Target ROAS:</b> {{calculateRoas(false)}} <b>:</b> 1</span>
        <span><b>Spend per £100 revenue:</b> {{calculateRoas(true)}}</span>
    </div>
    <div class="flex flex-col">
        <div class="flex flex-wrap w-full">
            <div class="w-full md:w-1/2 px-3 my-1">
                <mat-form-field>
                    <mat-label>Gross Margin (%)</mat-label>
                    <input matInput type="number" [formControlName]="'grossMargin'" max="100" min="0">
                </mat-form-field>
            </div>
            <div class="w-full md:w-1/2 px-3 my-1">
                <mat-form-field>
                    <mat-label>Net Margin Target(%)</mat-label>
                    <input matInput type="number" [formControlName]="'netMarginTarget'" max="100" min="0">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex flex-col">
        <div class="flex flex-wrap w-full">
            <div class="w-full md:w-1/2 px-3 my-1">
                <mat-form-field>
                    <mat-label>Additional Cost</mat-label>
                    £<input matInput type="number" [formControlName]="'additionalCost'" min="0">
                </mat-form-field>
            </div>
            <div class="w-full md:w-1/2 px-3 my-1">
                <mat-form-field>
                    <mat-label>Max CPC</mat-label>
                    £<input matInput type="number" [formControlName]="'maxCpc'" min="0">
                </mat-form-field>
            </div>
        </div>
    </div>
    <mat-label class="font-extrabold">Value Overrides</mat-label>
    <div class="flex flex-wrap w-full">
        <conversion-value-override class="w-full md:w-1/2" [parentFormGroup]="productForm" [configForm]="configForm" [currency]="currency"></conversion-value-override>
        <use-offline-factor class="w-full md:w-1/2" [parentFormGroup]="productForm" [configForm]="configForm" [currency]="currency"></use-offline-factor>
    </div>
    <returns-factor  [parentFormGroup]="productForm" [configForm]="configForm"></returns-factor>
</form>

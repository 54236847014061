import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DownloadMarketplace, DownloadMarketplace2LabelMapping } from 'app/core/enums/tracking-pixel/marketplace.download';
import { AlertService } from 'app/shared/_services';

@Component({
  selector: 'download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss'],
  providers: [AlertService]
})
export class DownloadDialogComponent {

  marketplaces = Object.values(DownloadMarketplace).filter(
    value => typeof value === 'number'
  );
  marketplace2LabelMapping = DownloadMarketplace2LabelMapping;

  selectedMarketplace: DownloadMarketplace;

  constructor(public dialogRef: MatDialogRef<DownloadDialogComponent>, private _alertService: AlertService,) { }

  onDownload(): void {
    if (this.selectedMarketplace !== undefined) {
      //console.log(this.selectedMarketplace);
      this.dialogRef.close({ selectedMarketplace: this.selectedMarketplace });
    } else {
      this._alertService.clear();
      this._alertService.error('Please select a marketplace');
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'skip-compliancy-check',
  templateUrl: './skip-compliancy-check.component.html',
  styleUrls: ['./skip-compliancy-check.component.scss']
})
export class SkipCompliancyCheckComponent {
  @Input() parentFormGroup;
}

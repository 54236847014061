import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'check-new-products-toggle',
  templateUrl: './check-new-products-toggle.component.html',
  styleUrls: ['./check-new-products-toggle.component.scss'],
})
export class CheckNewProductsToggleComponent {
  @Input() parentFormGroup;
}

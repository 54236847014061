import { Component, Input } from '@angular/core';
import { Marketplace, Marketplace2LabelMapping } from 'app/core/enums/job/marketplace';

@Component({
  selector: 'marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent {

  @Input() parentFormGroup;
  @Input() allowedMarketplaces: Marketplace[];

  marketplace2LabelMapping = Marketplace2LabelMapping;
  marketplaces = Object.values(Marketplace).filter(
    value => typeof value === 'number'
  );

  isAvailable(marketplace: Marketplace): boolean{
    return this.allowedMarketplaces.includes(marketplace);
  }

}

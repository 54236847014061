/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterDetailsComponent } from './filter-details/filter-details.component';
import { IFilterField, IFilter } from 'app/shared/_interfaces/filter-fields.interface';

@Component({
  selector: 'da-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})

export class FiltersComponent implements OnInit {

  @Output() onDataSetChange = new EventEmitter();

  @Output() onFilterChange = new EventEmitter();

  @Input() filterFields: IFilterField[] = [];

  @Input() originalDataSet: any[] = [];

  @Input() mutateDataset: boolean;

  filters: IFilter[];
  constructor(private _dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.filters = [];

  }
  removeFilter(index: number): void
  {
    this.filters.splice(index,1);
    this.checkPage();
  };

  editFilter(index: number): void{
      const dialogRef = this._dialog.open(FilterDetailsComponent,{
          disableClose: true,
          autoFocus: true,
          width: '800px',
          data: {fields:this.filterFields, filter: this.filters[index]},
      });

      dialogRef.afterClosed().subscribe((modifiedFilter)=> {
        if (!modifiedFilter) {
            return;
        }

        this.filters[index] = modifiedFilter;

        this.checkPage();
      });
  }

  clearFilters(): void {
    this.filters = [];
    this.checkPage();
  }

  updateDataSource(): void {
      let mutatedDataSet = JSON.parse(JSON.stringify(this.originalDataSet));

      for (const filter of this.filters) {
          switch (filter.field.type) {
              case 'set':
                  if (filter.operator.operator === '=') {
                      mutatedDataSet = mutatedDataSet.filter(ele => ele[filter.field.fieldName] === filter.value.value);
                  } else if (filter.operator.operator === 'Not Equal') {
                      mutatedDataSet = mutatedDataSet.filter(ele => ele[filter.field.fieldName] !== filter.value.value);
                  } else {
                      throw new Error ('Unknown set operator: ' + filter.operator.operator);
                  }
                  break;
              case 'string':
                  // @ts-ignore
                  const textValue = ((filter.value.caseInsensitivity ? filter.value.value.toLowerCase() : filter.value.value));
                  if (filter.operator.operator === '=') {
                      // @ts-ignore
                      mutatedDataSet = mutatedDataSet.filter(ele => (filter.value.caseInsensitivity ? ele[filter.field.fieldName].toLowerCase() : ele[filter.field.fieldName])
                          === textValue);
                  } else if (filter.operator.operator === 'Not Equal') {
                      // @ts-ignore
                      mutatedDataSet = mutatedDataSet.filter(ele => (filter.value.caseInsensitivity ? ele[filter.field.fieldName].toLowerCase() : ele[filter.field.fieldName])
                          !== textValue);
                  } else if (filter.operator.operator === 'Contains') {
                      // @ts-ignore
                      mutatedDataSet = mutatedDataSet.filter(ele => (filter.value.caseInsensitivity ? ele[filter.field.fieldName].toLowerCase() : ele[filter.field.fieldName])
                          .includes(textValue));
                  } else if (filter.operator.operator === 'Not Contains') {
                      // @ts-ignore
                      mutatedDataSet = mutatedDataSet.filter(ele => !(filter.value.caseInsensitivity ? ele[filter.field.fieldName].toLowerCase() : ele[filter.field.fieldName])
                          .includes(textValue));
                  } else if (filter.operator.operator === 'Starts With') {
                      // @ts-ignore
                      mutatedDataSet = mutatedDataSet.filter(ele => (filter.value.caseInsensitivity ? ele[filter.field.fieldName].toLowerCase() : ele[filter.field.fieldName])
                          .startsWith(textValue));
                  } else if (filter.operator.operator === 'Ends With') {
                      // @ts-ignore
                      mutatedDataSet = mutatedDataSet.filter(ele => (filter.value.caseInsensitivity ? ele[filter.field.fieldName].toLowerCase() : ele[filter.field.fieldName])
                          .endsWith(textValue));
                  } else {
                      throw new Error('Unknown string operator: ' + filter.operator.operator);
                  }
                  break;
              case 'boolean':
                  const booleanValue = (filter.value.value === 'true');
                  if (filter.operator.operator === '=') {
                      mutatedDataSet = mutatedDataSet.filter(ele => ele[filter.field.fieldName] === booleanValue);
                  } else if (filter.operator.operator === 'Not Equal') {
                      mutatedDataSet = mutatedDataSet.filter(ele => ele[filter.field.fieldName] !== booleanValue);
                  } else {
                      throw new Error ('Unknown boolean operator: ' + filter.operator.operator);
                  }
                  break;
              case 'number':
                  if (filter.operator.operator === '<') {
                      // eslint-disable-next-line max-len
                      mutatedDataSet = mutatedDataSet.filter(ele => (typeof(ele) === 'string' && ele.endsWith('%') ? parseFloat(ele.replace('%', ''))/100  : ele[filter.field.fieldName]) < filter.value.value);
                  } else if (filter.operator.operator === '<=') {
                      // eslint-disable-next-line max-len
                      mutatedDataSet = mutatedDataSet.filter(ele => (typeof(ele) === 'string' && ele.endsWith('%') ? parseFloat(ele.replace('%', ''))/100  : ele[filter.field.fieldName]) <= filter.value.value);
                  } else if (filter.operator.operator === '=') {
                      // eslint-disable-next-line eqeqeq, max-len
                      mutatedDataSet = mutatedDataSet.filter(ele => (typeof(ele) === 'string' && ele.endsWith('%') ? parseFloat(ele.replace('%', ''))/100  : ele[filter.field.fieldName]) == filter.value.value);
                  } else if (filter.operator.operator === 'Not Equal') {
                      // eslint-disable-next-line eqeqeq, max-len
                      mutatedDataSet = mutatedDataSet.filter(ele => (typeof(ele) === 'string' && ele.endsWith('%') ? parseFloat(ele.replace('%', ''))/100  : ele[filter.field.fieldName]) != filter.value.value);
                  } else if (filter.operator.operator === '>=') {
                      // eslint-disable-next-line max-len
                      mutatedDataSet = mutatedDataSet.filter(ele => (typeof(ele) === 'string' && ele.endsWith('%') ? parseFloat(ele.replace('%', ''))/100  : ele[filter.field.fieldName]) >= filter.value.value);
                  } else if (filter.operator.operator === '>') {
                      // eslint-disable-next-line max-len
                      mutatedDataSet = mutatedDataSet.filter(ele => (typeof(ele) === 'string' && ele.endsWith('%') ? parseFloat(ele.replace('%', ''))/100  : ele[filter.field.fieldName]) > filter.value.value);
                  } else {
                      throw new Error ('Unknown number operator: ' + filter.operator.operator);
                  }
                  break;
              case 'date':
                  if (filter.operator.operator === 'Before') {
                      mutatedDataSet = mutatedDataSet.filter(ele => new Date(ele[filter.field.fieldName]) < filter.value.value);
                  } else if (filter.operator.operator === 'On') {
                      mutatedDataSet = mutatedDataSet.filter((ele) => {
                          const converted = new Date(ele[filter.field.fieldName]);
                          // @ts-ignore
                          return converted.getDay() === filter.value.value.getDay() && converted.getMonth() === filter.value.value.getMonth() // @ts-ignore
                              && converted.getFullYear() === filter.value.value.getFullYear();
                      });
                  } else if (filter.operator.operator === 'Between') {
                      mutatedDataSet = mutatedDataSet.filter((ele) => {
                          const converted = new Date(ele[filter.field.fieldName]);
                          return converted >= filter.value.startDate && converted <= filter.value.endDate;
                      });
                  } else if (filter.operator.operator === 'After') {
                      mutatedDataSet = mutatedDataSet.filter(ele => new Date(ele[filter.field.fieldName]) > filter.value.value);
                  } else {
                      throw new Error ('Unknown date operator: ' + filter.operator.operator);
                  }
                  break;
              default:
                  throw new Error('Unknown Field Type: ' + filter.field.type);
          }
      }

      this.onDataSetChange.emit(mutatedDataSet);
  }

  createNewFilter(): void{
    const dialogRef = this._dialog.open(FilterDetailsComponent,{
      disableClose: true,
      autoFocus: true,
      width: '800px',
      data: {fields:this.filterFields},
    });

    dialogRef.afterClosed().subscribe((newFilter)=>{
        if (!newFilter) {
            return;
        }

      this.filters.push(newFilter);
      this.checkPage();
    });
  }

    checkPage(): void{
        if (this.mutateDataset) {
          this.updateDataSource();
        } else {
          this.onFilterChange.emit(this.filters);
        }
    }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ad-group-labels',
  templateUrl: './ad-group-labels.component.html',
  styleUrls: ['./ad-group-labels.component.scss']
})
export class AdGroupLabelsComponent  {

  @Input() parentFormGroup;
}

<div 
[ngSwitch]="switcher">
    <mat-spinner
        *ngSwitchCase="'Running'"
        [diameter]="25"
    ></mat-spinner>
    <mat-icon
        *ngSwitchCase="'Completed'"
        matTooltip="{{customTooltip ? customTooltip : 'Awaiting Review'}}"
        >preview</mat-icon
    >
    <mat-icon
        *ngSwitchCase="'Pushed'"
        style="color: green"
        matTooltip="{{customTooltip ? customTooltip : 'Pushed'}}"
        >done</mat-icon
    >
    <mat-icon
        *ngSwitchCase="'Failed'"
        style="color: orange"
        matTooltip="{{customTooltip ? customTooltip : 'Failed'}}"
        >warning</mat-icon
    >
    <mat-icon
        *ngSwitchCase="'Cancelled'"
        style="color: red"
        matTooltip="{{customTooltip ? customTooltip : 'Cancelled'}}"
        >cancel_presentation</mat-icon
    >
</div>

<mat-form-field [formGroup]="parentFormGroup">
    <mat-label>File Extension</mat-label>
    <mat-select [formControlName]="'FileExtension'">
        <mat-option value="delimited">Delimited</mat-option>
        <mat-option value="xml">XML</mat-option>
        <mat-option value="txt">TXT</mat-option>
    </mat-select>
    <mat-error
        *ngIf="parentFormGroup.get('FileExtension').hasError('required')"
    >
        File Extension is required
    </mat-error>
</mat-form-field>

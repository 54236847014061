/* eslint-disable @typescript-eslint/naming-convention */
export enum ReturnsFactorType{
  Off = 0,
  NoAdditionalCost = 1,
  AddAdditionalCostPerReturn = 2,
}

export const ReturnsFactorType2LabelMapping: Record<ReturnsFactorType, string> = {
    [ReturnsFactorType.Off]: 'Off',
    [ReturnsFactorType.NoAdditionalCost]: 'Free Returns',
    [ReturnsFactorType.AddAdditionalCostPerReturn]: 'Paid Returns'
};

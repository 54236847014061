/* eslint-disable @typescript-eslint/naming-convention */
export enum Marketplace {
    Amazon = 0,
    Bing = 1,
    Facebook = 2,
    Google = 3
}

export const Marketplace2LabelMapping: Record<Marketplace, string> = {
    [Marketplace.Amazon]: 'Amazon',
    [Marketplace.Bing]: 'Bing',
    [Marketplace.Facebook]: 'Facebook',
    [Marketplace.Google]: 'Google',
};

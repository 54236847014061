<div class="form-group mt-4" [formGroup]="parentFormGroup">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Publish Marketplace</mat-label>
        <mat-select [formControlName]="'marketplace'">
            <mat-option
                *ngFor="let marketplace of (selection && selection === 'mapper' ? mapperMarketplaces : publishMarketplaces)"
                [value]="marketplace"
            >
                {{ marketplace2LabelMapping[marketplace] }}
            </mat-option>
        </mat-select>
        <mat-error
            *ngIf="parentFormGroup.get('marketplace').hasError('required')"
        >
            Marketplace is required
        </mat-error>
    </mat-form-field>
</div>

/* eslint-disable @typescript-eslint/naming-convention */
export enum LimitType {
    Click = 0,
    Confidence = 1,
}

export const LimitType2LabelMapping: Record<LimitType, string> = {
    [LimitType.Click]: 'Click',
    [LimitType.Confidence]: 'Confidence',
};

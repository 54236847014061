<div [ngSwitch]="switcher">
    <span *ngSwitchCase="'google'">
        <fa-icon
            [icon]="['fab', 'google']"
            size="lg"
            matTooltip="Google - Gclid"
        ></fa-icon>
    </span>
    <span *ngSwitchCase="'googleGbraid'">
        <fa-icon
            [icon]="['fab', 'google']"
            size="lg"
            matTooltip="Google - Gbraid"
        ></fa-icon>
    </span>
    <span *ngSwitchCase="'maps'">
        <fa-icon
            [icon]="['fas', 'location-dot']"
            size="lg"
            matTooltip="Google Maps"
        ></fa-icon>
    </span>
    <span *ngSwitchCase="'facebook'"
        ><fa-icon
            [icon]="['fab', 'facebook']"
            size="lg"
            matTooltip="Facebook"
        ></fa-icon>
    </span>
    <span *ngSwitchCase="'amazon'"
        ><fa-icon
            [icon]="['fab', 'amazon']"
            size="lg"
            matTooltip="Amazon"
        ></fa-icon>
    </span>
    <span *ngSwitchCase="'bing'"
        ><fa-icon
            [icon]="['fab', 'microsoft']"
            size="lg"
            matTooltip="Bing"
        ></fa-icon>
    </span>
    <span *ngSwitchCase="'dreamAgility'">
        <img
        alt="Dream Agility"
        src="/assets/images/custom/favicon.png"
        class="center righted"
        style="width: 16px"
        matTooltip="Dream Agility"/>
    </span>
</div>

import { Component, Input } from '@angular/core';
import { CriterionMatchType, CriterionMatchType2LabelMapping } from 'app/core/enums/job/criterion.match.type';

@Component({
  selector: 'dp-match-type',
  templateUrl: './dp-match-type.component.html',
  styleUrls: ['./dp-match-type.component.scss']
})
export class DpMatchTypeComponent {
@Input() matchType: CriterionMatchType;

criterionMatchType2LabelMapping = CriterionMatchType2LabelMapping;


}

<mat-form-field [formGroup]="parentFormGroup">
    <mat-label>Delimiter Character</mat-label>
    <mat-select matInput [formControlName]="'DelimiterChar'">
        <mat-option value=",">,</mat-option>
        <mat-option value=";">;</mat-option>
        <mat-option value="|">|</mat-option>
        <mat-option value="{{'\t'}}">{{'\<tab>'}}</mat-option>
        <mat-option value=" ">{{'\<space>'}}</mat-option>
    </mat-select>
    <mat-error
        *ngIf="parentFormGroup.get('DelimiterChar').hasError('required')"
    >
    Delimiter Character is required
    </mat-error>

</mat-form-field>
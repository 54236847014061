import { Component, Input } from '@angular/core';
import { ReturnsFactorType, ReturnsFactorType2LabelMapping } from 'app/core/enums/job/returns.factor.type';

@Component({
  selector: 'returns-factor',
  templateUrl: './returns-factor.component.html',
  styleUrls: ['./returns-factor.component.scss']
})
export class ReturnsFactorComponent {
  @Input() parentFormGroup;
  @Input() configForm: boolean;

  protected readonly returnsFactorType = ReturnsFactorType;
  protected readonly returnsFactorType2LabelMapping = ReturnsFactorType2LabelMapping;

  checkDisplayOfReturns(field: string, truthValue: ReturnsFactorType): boolean
  {
    return !this.configForm || this.parentFormGroup.get(field).value === truthValue;
  }
}

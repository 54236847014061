<div class="filterComponent">
    <mat-icon class="filterIcon">filter_list</mat-icon>
    <div class="filterContainer" *ngFor="let filter of filters; let i=index;">
        <button class="font-medium" (click)="editFilter(i)">{{filter.field.displayName}} {{filter.operator.operator}} {{filter.value.displayText}}</button>
        <button class="font-medium filterContainerButton" (click)="removeFilter(i)">
            <mat-icon style="color: inherit;">close</mat-icon>
        </button>
    </div>
    <button class="font-medium filterContainerText" (click)="createNewFilter()">
        Add Filter
    </button>
    <button *ngIf="filters && filters.length > 0" class="font-medium filterContainerButton" (click)="clearFilters()" matTooltip="Clear Filters">
        <mat-icon>delete</mat-icon>
    </button>
</div>

<form [formGroup]="searchTermForm" #searchTermNgForm="ngForm" class="flex flex-col">
    <div class="flex flex-wrap w-full">  
        <div class="w-full md:w-1/3 px-3 my-2">
            <mat-form-field>
                <mat-label>Click Limit</mat-label>
                {{configForm ? config.clickLimit:''}}<input matInput type="number" [formControlName]="'clickLimit'">
            </mat-form-field>
        </div>

        <div class="w-full md:w-1/3 px-3 my-2">
            <mat-form-field>
                <mat-label>Loss Limit</mat-label>
                {{configForm ? config.lossLimit:''}}<input matInput type="number" [formControlName]="'lossLimit'">
            </mat-form-field>
        </div>

        <div class="w-full md:w-1/3 px-3 my-2">   
            <mat-form-field>
                <mat-label>Profit Target</mat-label>
                {{configForm ? config.profitTarget:''}}<input matInput type="number" [formControlName]="'profitTarget'">
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-wrap w-full">
        <div class="w-full md:w-1/2 px-3 mb-2">
            <mat-form-field>
                <mat-label>Gross Margin (%)</mat-label>
                {{configForm ? config.grossMargin:''}}<input matInput type="number" [formControlName]="'grossMargin'" max="100" min="0">
            </mat-form-field>
        </div>

        <div class="w-full md:w-1/2 px-3 mb-2">
            <mat-form-field>
                <mat-label>Additional Cost</mat-label>
                £{{configForm ? config.additionalCost:''}}<input matInput type="number" [formControlName]="'additionalCost'" min="0">
            </mat-form-field>
        </div>

    </div>
    <mat-label class="font-extrabold px-3 mb-2">Value Overrides</mat-label>
    <div class="flex flex-wrap w-full">   
        <conversion-value-override class="w-full md:w-1/2" [parentFormGroup]="searchTermForm" [configForm]="configForm" [currency]="currency"></conversion-value-override>
        <use-offline-factor class="w-full md:w-1/2" [parentFormGroup]="searchTermForm" [configForm]="configForm" [currency]="currency"></use-offline-factor>
    </div>
    <returns-factor [parentFormGroup]="searchTermForm" [configForm]="configForm"></returns-factor>
</form>
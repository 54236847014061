/* eslint-disable @typescript-eslint/naming-convention */
export enum Country {
    AF = 0,    //Afghanistan
    AL = 1,    //Albania
    DZ = 2,    //Algeria
    AS = 3,    //American Samoa
    AD = 4,    //Andorra
    AO = 5,    //Angola
    AQ = 6,    //Antarctica
    AG = 7,    //Antigua and Barbuda
    AR = 8,    //Argentina
    AM = 9,    //Armenia
    AU = 10,    //Australia
    AT = 11,    //Austria
    AZ = 12,    //Azerbaijan
    BH = 13,    //Bahrain
    BD = 14,    //Bangladesh
    BB = 15,    //Barbados
    BY = 16,    //Belarus
    BE = 17,    //Belgium
    BZ = 18,    //Belize
    BJ = 19,    //Benin
    BT = 20,    //Bhutan
    BO = 21,    //Bolivia
    BA = 22,    //Bosnia and Herzegovina
    BW = 23,    //Botswana
    BR = 24,    //Brazil
    BN = 25,    //Brunei
    BG = 26,    //Bulgaria
    BF = 27,    //Burkina Faso
    BI = 28,    //Burundi
    KH = 29,    //Cambodia
    CM = 30,    //Cameroon
    CA = 31,    //Canada
    CV = 32,    //Cape Verde
    BQ = 33,    //Caribbean Netherlands
    CF = 34,    //Central African Republic
    TD = 35,    //Chad
    CL = 36,    //Chile
    CN = 37,    //China
    CX = 38,    //Christmas Island
    CC = 39,    //Cocos (Keeling) Islands
    CO = 40,    //Colombia
    KM = 41,    //Comoros
    CK = 42,    //Cook Islands
    CR = 43,    //Costa Rica
    CI = 44,    //Cote d'Ivoire
    HR = 45,    //Croatia
    CW = 46,    //Curacao
    CY = 47,    //Cyprus
    CZ = 48,    //Czechia
    CD = 49,    //Democratic Republic of the Congo
    DK = 50,    //Denmark
    DJ = 51,    //Djibouti
    DM = 52,    //Dominica
    DO = 53,    //Dominican Republic
    EC = 54,    //Ecuador
    EG = 55,    //Egypt
    SV = 56,    //El Salvador
    GQ = 57,    //Equatorial Guinea
    ER = 58,    //Eritrea
    EE = 59,    //Estonia
    SZ = 60,    //Eswatini
    ET = 61,    //Ethiopia
    FJ = 62,    //Fiji
    FI = 63,    //Finland
    FR = 64,    //France
    PF = 65,    //French Polynesia
    TF = 66,    //French Southern and Antarctic Lands
    GA = 67,    //Gabon
    GE = 68,    //Georgia
    DE = 69,    //Germany
    GH = 70,    //Ghana
    GR = 71,    //Greece
    GD = 72,    //Grenada
    GU = 73,    //Guam
    GT = 74,    //Guatemala
    GG = 75,    //Guernsey
    GN = 76,    //Guinea
    GW = 77,    //Guinea-Bissau
    GY = 78,    //Guyana
    HT = 79,    //Haiti
    HM = 80,    //Heard Island and McDonald Islands
    HN = 81,    //Honduras
    HU = 82,    //Hungary
    IS = 83,    //Iceland
    IN = 84,    //India
    ID = 85,    //Indonesia
    IQ = 86,    //Iraq
    IE = 87,    //Ireland
    IM = 88,    //Isle of Man
    IL = 89,    //Israel
    IT = 90,    //Italy
    JM = 91,    //Jamaica
    JP = 92,    //Japan
    JE = 93,    //Jersey
    JO = 94,    //Jordan
    KZ = 95,    //Kazakhstan
    KE = 96,    //Kenya
    KI = 97,    //Kiribati
    KW = 98,    //Kuwait
    KG = 99,    //Kyrgyzstan
    LA = 100,    //Laos
    LV = 101,    //Latvia
    LB = 102,    //Lebanon
    LS = 103,    //Lesotho
    LR = 104,    //Liberia
    LY = 105,    //Libya
    LI = 106,    //Liechtenstein
    LT = 107,    //Lithuania
    LU = 108,    //Luxembourg
    MG = 109,    //Madagascar
    MW = 110,    //Malawi
    MY = 111,    //Malaysia
    MV = 112,    //Maldives
    ML = 113,    //Mali
    MT = 114,    //Malta
    MH = 115,    //Marshall Islands
    MR = 116,    //Mauritania
    MU = 117,    //Mauritius
    MX = 118,    //Mexico
    FM = 119,    //Micronesia
    MD = 120,    //Moldova
    MC = 121,    //Monaco
    MN = 122,    //Mongolia
    ME = 123,    //Montenegro
    MA = 124,    //Morocco
    MZ = 125,    //Mozambique
    MM = 126,    //Myanmar (Burma)
    NA = 127,    //Namibia
    NR = 128,    //Nauru
    NP = 129,    //Nepal
    NL = 130,    //Netherlands
    NC = 131,    //New Caledonia
    NZ = 132,    //New Zealand
    NI = 133,    //Nicaragua
    NE = 134,    //Niger
    NG = 135,    //Nigeria
    NU = 136,    //Niue
    NF = 137,    //Norfolk Island
    MK = 138,    //North Macedonia
    MP = 139,    //Northern Mariana Islands
    NO = 140,    //Norway
    OM = 141,    //Oman
    PK = 142,    //Pakistan
    PW = 143,    //Palau
    PA = 144,    //Panama
    PG = 145,    //Papua New Guinea
    PY = 146,    //Paraguay
    PE = 147,    //Peru
    PH = 148,    //Philippines
    PN = 149,    //Pitcairn Islands
    PL = 150,    //Poland
    PT = 151,    //Portugal
    QA = 152,    //Qatar
    CG = 153,    //Republic of the Congo
    RO = 154,    //Romania
    RU = 155,    //Russia
    RW = 156,    //Rwanda
    BL = 157,    //Saint Barthelemy
    SH = 158,    //Saint Helena, Ascension and Tristan da Cunha
    KN = 159,    //Saint Kitts and Nevis
    LC = 160,    //Saint Lucia
    MF = 161,    //Saint Martin
    PM = 162,    //Saint Pierre and Miquelon
    VC = 163,    //Saint Vincent and the Grenadines
    WS = 164,    //Samoa
    SM = 165,    //San Marino
    ST = 166,    //Sao Tome and Principe
    SA = 167,    //Saudi Arabia
    SN = 168,    //Senegal
    RS = 169,    //Serbia
    SC = 170,    //Seychelles
    SL = 171,    //Sierra Leone
    SG = 172,    //Singapore
    SX = 173,    //Sint Maarten
    SK = 174,    //Slovakia
    SI = 175,    //Slovenia
    SB = 176,    //Solomon Islands
    SO = 177,    //Somalia
    ZA = 178,    //South Africa
    GS = 179,    //South Georgia and the South Sandwich Islands
    KR = 180,    //South Korea
    SS = 181,    //South Sudan
    ES = 182,    //Spain
    LK = 183,    //Sri Lanka
    SD = 184,    //Sudan
    SR = 185,    //Suriname
    SE = 186,    //Sweden
    CH = 187,    //Switzerland
    TJ = 188,    //Tajikistan
    TZ = 189,    //Tanzania
    TH = 190,    //Thailand
    BS = 191,    //The Bahamas
    GM = 192,    //The Gambia
    TL = 193,    //Timor-Leste
    TG = 194,    //Togo
    TK = 195,    //Tokelau
    TO = 196,    //Tonga
    TT = 197,    //Trinidad and Tobago
    TN = 198,    //Tunisia
    TR = 199,    //Turkiye
    TM = 200,    //Turkmenistan
    TV = 201,    //Tuvalu
    UG = 202,    //Uganda
    UA = 203,    //Ukraine
    AE = 204,    //United Arab Emirates
    GB = 205,    //United Kingdom
    US = 206,    //United States
    UM = 207,    //United States Minor Outlying Islands
    UY = 208,    //Uruguay
    UZ = 209,    //Uzbekistan
    VU = 210,    //Vanuatu
    VA = 211,    //Vatican City
    VE = 212,    //Venezuela
    VN = 213,    //Vietnam
    WF = 214,    //Wallis and Futuna
    YE = 215,    //Yemen
    ZM = 216,    //Zambia
    ZW = 217,    //Zimbabwe
}
export const Country2LabelMapping: Record<Country, string> = {
    [Country.AF]: 'Afghanistan',
    [Country.AL]: 'Albania',
    [Country.DZ]: 'Algeria',
    [Country.AS]: 'American Samoa',
    [Country.AD]: 'Andorra',
    [Country.AO]: 'Angola',
    [Country.AQ]: 'Antarctica',
    [Country.AG]: 'Antigua and Barbuda',
    [Country.AR]: 'Argentina',
    [Country.AM]: 'Armenia',
    [Country.AU]: 'Australia',
    [Country.AT]: 'Austria',
    [Country.AZ]: 'Azerbaijan',
    [Country.BH]: 'Bahrain',
    [Country.BD]: 'Bangladesh',
    [Country.BB]: 'Barbados',
    [Country.BY]: 'Belarus',
    [Country.BE]: 'Belgium',
    [Country.BZ]: 'Belize',
    [Country.BJ]: 'Benin',
    [Country.BT]: 'Bhutan',
    [Country.BO]: 'Bolivia',
    [Country.BA]: 'Bosnia and Herzegovina',
    [Country.BW]: 'Botswana',
    [Country.BR]: 'Brazil',
    [Country.BN]: 'Brunei',
    [Country.BG]: 'Bulgaria',
    [Country.BF]: 'Burkina Faso',
    [Country.BI]: 'Burundi',
    [Country.KH]: 'Cambodia',
    [Country.CM]: 'Cameroon',
    [Country.CA]: 'Canada',
    [Country.CV]: 'Cape Verde',
    [Country.BQ]: 'Caribbean Netherlands',
    [Country.CF]: 'Central African Republic',
    [Country.TD]: 'Chad',
    [Country.CL]: 'Chile',
    [Country.CN]: 'China',
    [Country.CX]: 'Christmas Island',
    [Country.CC]: 'Cocos (Keeling) Islands',
    [Country.CO]: 'Colombia',
    [Country.KM]: 'Comoros',
    [Country.CK]: 'Cook Islands',
    [Country.CR]: 'Costa Rica',
    [Country.CI]: 'Cote d\'Ivoire',
    [Country.HR]: 'Croatia',
    [Country.CW]: 'Curacao',
    [Country.CY]: 'Cyprus',
    [Country.CZ]: 'Czechia',
    [Country.CD]: 'Democratic Republic of the Congo',
    [Country.DK]: 'Denmark',
    [Country.DJ]: 'Djibouti',
    [Country.DM]: 'Dominica',
    [Country.DO]: 'Dominican Republic',
    [Country.EC]: 'Ecuador',
    [Country.EG]: 'Egypt',
    [Country.SV]: 'El Salvador',
    [Country.GQ]: 'Equatorial Guinea',
    [Country.ER]: 'Eritrea',
    [Country.EE]: 'Estonia',
    [Country.SZ]: 'Eswatini',
    [Country.ET]: 'Ethiopia',
    [Country.FJ]: 'Fiji',
    [Country.FI]: 'Finland',
    [Country.FR]: 'France',
    [Country.PF]: 'French Polynesia',
    [Country.TF]: 'French Southern and Antarctic Lands',
    [Country.GA]: 'Gabon',
    [Country.GE]: 'Georgia',
    [Country.DE]: 'Germany',
    [Country.GH]: 'Ghana',
    [Country.GR]: 'Greece',
    [Country.GD]: 'Grenada',
    [Country.GU]: 'Guam',
    [Country.GT]: 'Guatemala',
    [Country.GG]: 'Guernsey',
    [Country.GN]: 'Guinea',
    [Country.GW]: 'Guinea-Bissau',
    [Country.GY]: 'Guyana',
    [Country.HT]: 'Haiti',
    [Country.HM]: 'Heard Island and McDonald Islands',
    [Country.HN]: 'Honduras',
    [Country.HU]: 'Hungary',
    [Country.IS]: 'Iceland',
    [Country.IN]: 'India',
    [Country.ID]: 'Indonesia',
    [Country.IQ]: 'Iraq',
    [Country.IE]: 'Ireland',
    [Country.IM]: 'Isle of Man',
    [Country.IL]: 'Israel',
    [Country.IT]: 'Italy',
    [Country.JM]: 'Jamaica',
    [Country.JP]: 'Japan',
    [Country.JE]: 'Jersey',
    [Country.JO]: 'Jordan',
    [Country.KZ]: 'Kazakhstan',
    [Country.KE]: 'Kenya',
    [Country.KI]: 'Kiribati',
    [Country.KW]: 'Kuwait',
    [Country.KG]: 'Kyrgyzstan',
    [Country.LA]: 'Laos',
    [Country.LV]: 'Latvia',
    [Country.LB]: 'Lebanon',
    [Country.LS]: 'Lesotho',
    [Country.LR]: 'Liberia',
    [Country.LY]: 'Libya',
    [Country.LI]: 'Liechtenstein',
    [Country.LT]: 'Lithuania',
    [Country.LU]: 'Luxembourg',
    [Country.MG]: 'Madagascar',
    [Country.MW]: 'Malawi',
    [Country.MY]: 'Malaysia',
    [Country.MV]: 'Maldives',
    [Country.ML]: 'Mali',
    [Country.MT]: 'Malta',
    [Country.MH]: 'Marshall Islands',
    [Country.MR]: 'Mauritania',
    [Country.MU]: 'Mauritius',
    [Country.MX]: 'Mexico',
    [Country.FM]: 'Micronesia',
    [Country.MD]: 'Moldova',
    [Country.MC]: 'Monaco',
    [Country.MN]: 'Mongolia',
    [Country.ME]: 'Montenegro',
    [Country.MA]: 'Morocco',
    [Country.MZ]: 'Mozambique',
    [Country.MM]: 'Myanmar (Burma)',
    [Country.NA]: 'Namibia',
    [Country.NR]: 'Nauru',
    [Country.NP]: 'Nepal',
    [Country.NL]: 'Netherlands',
    [Country.NC]: 'New Caledonia',
    [Country.NZ]: 'New Zealand',
    [Country.NI]: 'Nicaragua',
    [Country.NE]: 'Niger',
    [Country.NG]: 'Nigeria',
    [Country.NU]: 'Niue',
    [Country.NF]: 'Norfolk Island',
    [Country.MK]: 'North Macedonia',
    [Country.MP]: 'Northern Mariana Islands',
    [Country.NO]: 'Norway',
    [Country.OM]: 'Oman',
    [Country.PK]: 'Pakistan',
    [Country.PW]: 'Palau',
    [Country.PA]: 'Panama',
    [Country.PG]: 'Papua New Guinea',
    [Country.PY]: 'Paraguay',
    [Country.PE]: 'Peru',
    [Country.PH]: 'Philippines',
    [Country.PN]: 'Pitcairn Islands',
    [Country.PL]: 'Poland',
    [Country.PT]: 'Portugal',
    [Country.QA]: 'Qatar',
    [Country.CG]: 'Republic of the Congo',
    [Country.RO]: 'Romania',
    [Country.RU]: 'Russia',
    [Country.RW]: 'Rwanda',
    [Country.BL]: 'Saint Barthelemy',
    [Country.SH]: 'Saint Helena, Ascension and Tristan da Cunha',
    [Country.KN]: 'Saint Kitts and Nevis',
    [Country.LC]: 'Saint Lucia',
    [Country.MF]: 'Saint Martin',
    [Country.PM]: 'Saint Pierre and Miquelon',
    [Country.VC]: 'Saint Vincent and the Grenadines',
    [Country.WS]: 'Samoa',
    [Country.SM]: 'San Marino',
    [Country.ST]: 'Sao Tome and Principe',
    [Country.SA]: 'Saudi Arabia',
    [Country.SN]: 'Senegal',
    [Country.RS]: 'Serbia',
    [Country.SC]: 'Seychelles',
    [Country.SL]: 'Sierra Leone',
    [Country.SG]: 'Singapore',
    [Country.SX]: 'Sint Maarten',
    [Country.SK]: 'Slovakia',
    [Country.SI]: 'Slovenia',
    [Country.SB]: 'Solomon Islands',
    [Country.SO]: 'Somalia',
    [Country.ZA]: 'South Africa',
    [Country.GS]: 'South Georgia and the South Sandwich Islands',
    [Country.KR]: 'South Korea',
    [Country.SS]: 'South Sudan',
    [Country.ES]: 'Spain',
    [Country.LK]: 'Sri Lanka',
    [Country.SD]: 'Sudan',
    [Country.SR]: 'Suriname',
    [Country.SE]: 'Sweden',
    [Country.CH]: 'Switzerland',
    [Country.TJ]: 'Tajikistan',
    [Country.TZ]: 'Tanzania',
    [Country.TH]: 'Thailand',
    [Country.BS]: 'The Bahamas',
    [Country.GM]: 'The Gambia',
    [Country.TL]: 'Timor-Leste',
    [Country.TG]: 'Togo',
    [Country.TK]: 'Tokelau',
    [Country.TO]: 'Tonga',
    [Country.TT]: 'Trinidad and Tobago',
    [Country.TN]: 'Tunisia',
    [Country.TR]: 'Turkiye',
    [Country.TM]: 'Turkmenistan',
    [Country.TV]: 'Tuvalu',
    [Country.UG]: 'Uganda',
    [Country.UA]: 'Ukraine',
    [Country.AE]: 'United Arab Emirates',
    [Country.GB]: 'United Kingdom',
    [Country.US]: 'United States',
    [Country.UM]: 'United States Minor Outlying Islands',
    [Country.UY]: 'Uruguay',
    [Country.UZ]: 'Uzbekistan',
    [Country.VU]: 'Vanuatu',
    [Country.VA]: 'Vatican City',
    [Country.VE]: 'Venezuela',
    [Country.VN]: 'Vietnam',
    [Country.WF]: 'Wallis and Futuna',
    [Country.YE]: 'Yemen',
    [Country.ZM]: 'Zambia',
    [Country.ZW]: 'Zimbabwe',
};

export const CountryFilterOptions = [
    { 'value': 'AF', 'display': 'Afghanistan'},
    { 'value': 'AL', 'display': 'Albania'},
    { 'value': 'DZ', 'display': 'Algeria'},
    { 'value': 'AS', 'display': 'American Samoa'},
    { 'value': 'AD', 'display': 'Andorra'},
    { 'value': 'AO', 'display': 'Angola'},
    { 'value': 'AQ', 'display': 'Antarctica'},
    { 'value': 'AG', 'display': 'Antigua and Barbuda'},
    { 'value': 'AR', 'display': 'Argentina'},
    { 'value': 'AM', 'display': 'Armenia'},
    { 'value': 'AU', 'display': 'Australia'},
    { 'value': 'AT', 'display': 'Austria'},
    { 'value': 'AZ', 'display': 'Azerbaijan'},
    { 'value': 'BH', 'display': 'Bahrain'},
    { 'value': 'BD', 'display': 'Bangladesh'},
    { 'value': 'BB', 'display': 'Barbados'},
    { 'value': 'BY', 'display': 'Belarus'},
    { 'value': 'BE', 'display': 'Belgium'},
    { 'value': 'BZ', 'display': 'Belize'},
    { 'value': 'BJ', 'display': 'Benin'},
    { 'value': 'BT', 'display': 'Bhutan'},
    { 'value': 'BO', 'display': 'Bolivia'},
    { 'value': 'BA', 'display': 'Bosnia and Herzegovina'},
    { 'value': 'BW', 'display': 'Botswana'},
    { 'value': 'BR', 'display': 'Brazil'},
    { 'value': 'BN', 'display': 'Brunei'},
    { 'value': 'BG', 'display': 'Bulgaria'},
    { 'value': 'BF', 'display': 'Burkina Faso'},
    { 'value': 'BI', 'display': 'Burundi'},
    { 'value': 'KH', 'display': 'Cambodia'},
    { 'value': 'CM', 'display': 'Cameroon'},
    { 'value': 'CA', 'display': 'Canada'},
    { 'value': 'CV', 'display': 'Cape Verde'},
    { 'value': 'BQ', 'display': 'Caribbean Netherlands'},
    { 'value': 'CF', 'display': 'Central African Republic'},
    { 'value': 'TD', 'display': 'Chad'},
    { 'value': 'CL', 'display': 'Chile'},
    { 'value': 'CN', 'display': 'China'},
    { 'value': 'CX', 'display': 'Christmas Island'},
    { 'value': 'CC', 'display': 'Cocos (Keeling) Islands'},
    { 'value': 'CO', 'display': 'Colombia'},
    { 'value': 'KM', 'display': 'Comoros'},
    { 'value': 'CK', 'display': 'Cook Islands'},
    { 'value': 'CR', 'display': 'Costa Rica'},
    { 'value': 'CI', 'display': 'Cote d\'Ivoire'},
    { 'value': 'HR', 'display': 'Croatia'},
    { 'value': 'CW', 'display': 'Curacao'},
    { 'value': 'CY', 'display': 'Cyprus'},
    { 'value': 'CZ', 'display': 'Czechia'},
    { 'value': 'CD', 'display': 'Democratic Republic of the Congo'},
    { 'value': 'DK', 'display': 'Denmark'},
    { 'value': 'DJ', 'display': 'Djibouti'},
    { 'value': 'DM', 'display': 'Dominica'},
    { 'value': 'DO', 'display': 'Dominican Republic'},
    { 'value': 'EC', 'display': 'Ecuador'},
    { 'value': 'EG', 'display': 'Egypt'},
    { 'value': 'SV', 'display': 'El Salvador'},
    { 'value': 'GQ', 'display': 'Equatorial Guinea'},
    { 'value': 'ER', 'display': 'Eritrea'},
    { 'value': 'EE', 'display': 'Estonia'},
    { 'value': 'SZ', 'display': 'Eswatini'},
    { 'value': 'ET', 'display': 'Ethiopia'},
    { 'value': 'FJ', 'display': 'Fiji'},
    { 'value': 'FI', 'display': 'Finland'},
    { 'value': 'FR', 'display': 'France'},
    { 'value': 'PF', 'display': 'French Polynesia'},
    { 'value': 'TF', 'display': 'French Southern and Antarctic Lands'},
    { 'value': 'GA', 'display': 'Gabon'},
    { 'value': 'GE', 'display': 'Georgia'},
    { 'value': 'DE', 'display': 'Germany'},
    { 'value': 'GH', 'display': 'Ghana'},
    { 'value': 'GR', 'display': 'Greece'},
    { 'value': 'GD', 'display': 'Grenada'},
    { 'value': 'GU', 'display': 'Guam'},
    { 'value': 'GT', 'display': 'Guatemala'},
    { 'value': 'GG', 'display': 'Guernsey'},
    { 'value': 'GN', 'display': 'Guinea'},
    { 'value': 'GW', 'display': 'Guinea-Bissau'},
    { 'value': 'GY', 'display': 'Guyana'},
    { 'value': 'HT', 'display': 'Haiti'},
    { 'value': 'HM', 'display': 'Heard Island and McDonald Islands'},
    { 'value': 'HN', 'display': 'Honduras'},
    { 'value': 'HU', 'display': 'Hungary'},
    { 'value': 'IS', 'display': 'Iceland'},
    { 'value': 'IN', 'display': 'India'},
    { 'value': 'ID', 'display': 'Indonesia'},
    { 'value': 'IQ', 'display': 'Iraq'},
    { 'value': 'IE', 'display': 'Ireland'},
    { 'value': 'IM', 'display': 'Isle of Man'},
    { 'value': 'IL', 'display': 'Israel'},
    { 'value': 'IT', 'display': 'Italy'},
    { 'value': 'JM', 'display': 'Jamaica'},
    { 'value': 'JP', 'display': 'Japan'},
    { 'value': 'JE', 'display': 'Jersey'},
    { 'value': 'JO', 'display': 'Jordan'},
    { 'value': 'KZ', 'display': 'Kazakhstan'},
    { 'value': 'KE', 'display': 'Kenya'},
    { 'value': 'KI', 'display': 'Kiribati'},
    { 'value': 'KW', 'display': 'Kuwait'},
    { 'value': 'KG', 'display': 'Kyrgyzstan'},
    { 'value': 'LA', 'display': 'Laos'},
    { 'value': 'LV', 'display': 'Latvia'},
    { 'value': 'LB', 'display': 'Lebanon'},
    { 'value': 'LS', 'display': 'Lesotho'},
    { 'value': 'LR', 'display': 'Liberia'},
    { 'value': 'LY', 'display': 'Libya'},
    { 'value': 'LI', 'display': 'Liechtenstein'},
    { 'value': 'LT', 'display': 'Lithuania'},
    { 'value': 'LU', 'display': 'Luxembourg'},
    { 'value': 'MG', 'display': 'Madagascar'},
    { 'value': 'MW', 'display': 'Malawi'},
    { 'value': 'MY', 'display': 'Malaysia'},
    { 'value': 'MV', 'display': 'Maldives'},
    { 'value': 'ML', 'display': 'Mali'},
    { 'value': 'MT', 'display': 'Malta'},
    { 'value': 'MH', 'display': 'Marshall Islands'},
    { 'value': 'MR', 'display': 'Mauritania'},
    { 'value': 'MU', 'display': 'Mauritius'},
    { 'value': 'MX', 'display': 'Mexico'},
    { 'value': 'FM', 'display': 'Micronesia'},
    { 'value': 'MD', 'display': 'Moldova'},
    { 'value': 'MC', 'display': 'Monaco'},
    { 'value': 'MN', 'display': 'Mongolia'},
    { 'value': 'ME', 'display': 'Montenegro'},
    { 'value': 'MA', 'display': 'Morocco'},
    { 'value': 'MZ', 'display': 'Mozambique'},
    { 'value': 'MM', 'display': 'Myanmar (Burma)'},
    { 'value': 'NA', 'display': 'Namibia'},
    { 'value': 'NR', 'display': 'Nauru'},
    { 'value': 'NP', 'display': 'Nepal'},
    { 'value': 'NL', 'display': 'Netherlands'},
    { 'value': 'NC', 'display': 'New Caledonia'},
    { 'value': 'NZ', 'display': 'New Zealand'},
    { 'value': 'NI', 'display': 'Nicaragua'},
    { 'value': 'NE', 'display': 'Niger'},
    { 'value': 'NG', 'display': 'Nigeria'},
    { 'value': 'NU', 'display': 'Niue'},
    { 'value': 'NF', 'display': 'Norfolk Island'},
    { 'value': 'MK', 'display': 'North Macedonia'},
    { 'value': 'MP', 'display': 'Northern Mariana Islands'},
    { 'value': 'NO', 'display': 'Norway'},
    { 'value': 'OM', 'display': 'Oman'},
    { 'value': 'PK', 'display': 'Pakistan'},
    { 'value': 'PW', 'display': 'Palau'},
    { 'value': 'PA', 'display': 'Panama'},
    { 'value': 'PG', 'display': 'Papua New Guinea'},
    { 'value': 'PY', 'display': 'Paraguay'},
    { 'value': 'PE', 'display': 'Peru'},
    { 'value': 'PH', 'display': 'Philippines'},
    { 'value': 'PN', 'display': 'Pitcairn Islands'},
    { 'value': 'PL', 'display': 'Poland'},
    { 'value': 'PT', 'display': 'Portugal'},
    { 'value': 'QA', 'display': 'Qatar'},
    { 'value': 'CG', 'display': 'Republic of the Congo'},
    { 'value': 'RO', 'display': 'Romania'},
    { 'value': 'RU', 'display': 'Russia'},
    { 'value': 'RW', 'display': 'Rwanda'},
    { 'value': 'BL', 'display': 'Saint Barthelemy'},
    { 'value': 'SH', 'display': 'Saint Helena, Ascension and Tristan da Cunha'},
    { 'value': 'KN', 'display': 'Saint Kitts and Nevis'},
    { 'value': 'LC', 'display': 'Saint Lucia'},
    { 'value': 'MF', 'display': 'Saint Martin'},
    { 'value': 'PM', 'display': 'Saint Pierre and Miquelon'},
    { 'value': 'VC', 'display': 'Saint Vincent and the Grenadines'},
    { 'value': 'WS', 'display': 'Samoa'},
    { 'value': 'SM', 'display': 'San Marino'},
    { 'value': 'ST', 'display': 'Sao Tome and Principe'},
    { 'value': 'SA', 'display': 'Saudi Arabia'},
    { 'value': 'SN', 'display': 'Senegal'},
    { 'value': 'RS', 'display': 'Serbia'},
    { 'value': 'SC', 'display': 'Seychelles'},
    { 'value': 'SL', 'display': 'Sierra Leone'},
    { 'value': 'SG', 'display': 'Singapore'},
    { 'value': 'SX', 'display': 'Sint Maarten'},
    { 'value': 'SK', 'display': 'Slovakia'},
    { 'value': 'SI', 'display': 'Slovenia'},
    { 'value': 'SB', 'display': 'Solomon Islands'},
    { 'value': 'SO', 'display': 'Somalia'},
    { 'value': 'ZA', 'display': 'South Africa'},
    { 'value': 'GS', 'display': 'South Georgia and the South Sandwich Islands'},
    { 'value': 'KR', 'display': 'South Korea'},
    { 'value': 'SS', 'display': 'South Sudan'},
    { 'value': 'ES', 'display': 'Spain'},
    { 'value': 'LK', 'display': 'Sri Lanka'},
    { 'value': 'SD', 'display': 'Sudan'},
    { 'value': 'SR', 'display': 'Suriname'},
    { 'value': 'SE', 'display': 'Sweden'},
    { 'value': 'CH', 'display': 'Switzerland'},
    { 'value': 'TJ', 'display': 'Tajikistan'},
    { 'value': 'TZ', 'display': 'Tanzania'},
    { 'value': 'TH', 'display': 'Thailand'},
    { 'value': 'BS', 'display': 'The Bahamas'},
    { 'value': 'GM', 'display': 'The Gambia'},
    { 'value': 'TL', 'display': 'Timor-Leste'},
    { 'value': 'TG', 'display': 'Togo'},
    { 'value': 'TK', 'display': 'Tokelau'},
    { 'value': 'TO', 'display': 'Tonga'},
    { 'value': 'TT', 'display': 'Trinidad and Tobago'},
    { 'value': 'TN', 'display': 'Tunisia'},
    { 'value': 'TR', 'display': 'Turkiye'},
    { 'value': 'TM', 'display': 'Turkmenistan'},
    { 'value': 'TV', 'display': 'Tuvalu'},
    { 'value': 'UG', 'display': 'Uganda'},
    { 'value': 'UA', 'display': 'Ukraine'},
    { 'value': 'AE', 'display': 'United Arab Emirates'},
    { 'value': 'GB', 'display': 'United Kingdom'},
    { 'value': 'US', 'display': 'United States'},
    { 'value': 'UM', 'display': 'United States Minor Outlying Islands'},
    { 'value': 'UY', 'display': 'Uruguay'},
    { 'value': 'UZ', 'display': 'Uzbekistan'},
    { 'value': 'VU', 'display': 'Vanuatu'},
    { 'value': 'VA', 'display': 'Vatican City'},
    { 'value': 'VE', 'display': 'Venezuela'},
    { 'value': 'VN', 'display': 'Vietnam'},
    { 'value': 'WF', 'display': 'Wallis and Futuna'},
    { 'value': 'YE', 'display': 'Yemen'},
    { 'value': 'ZM', 'display': 'Zambia'},
    { 'value': 'ZW', 'display': 'Zimbabwe'}
];

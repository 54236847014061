/* eslint-disable guard-for-in */
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi
{
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    private _customAsidelNavigation: FuseNavigationItem[] = [];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, private _authService: AuthService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {

        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                this._customAsidelNavigation = [];

                const userRole = AuthUtils.getRoleFromToken(this._authService.accessToken);
                if (userRole) {
                    for (const key in this._compactNavigation) {
                        if (this._compactNavigation[key].allowedRoles) {
                            const allowedRoles = this._compactNavigation[key].allowedRoles;
                            if (allowedRoles.includes(userRole)) {
                                this._customAsidelNavigation.push(this._compactNavigation[key]);
                            }
                        }
                        else {
                            this._customAsidelNavigation.push(this._compactNavigation[key]);
                        }
                    }

                }

                // Fill compact navigation children using the default navigation
                this._customAsidelNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if (defaultNavItem.id === compactNavItem.id) {
                            //compactNavItem.children = cloneDeep(defaultNavItem.children);
                            compactNavItem.children = [];
                            if (defaultNavItem.children) {

                                const childrens = defaultNavItem.children;
                                for (const childKey in childrens) {

                                    if (childrens[childKey].allowedRoles && childrens[childKey].children) {
                                        const nestedChildrens = childrens[childKey].children;
                                        const allowedRoles = childrens[childKey].allowedRoles;
                                        if (allowedRoles.includes(userRole)) {
                                            compactNavItem.children.push(childrens[childKey]);
                                        }

                                    }
                                    else {
                                        compactNavItem.children.push(childrens[childKey]);
                                    }
                                }
                            }
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });


                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._customAsidelNavigation),
                        default   : cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ];
            });
    }
}
